import React from "react";
import { formatMonetaryValue } from "../../utils/Utils";

function MSTableItem({
  item,
  handleClick,
  isChecked,
  showCheckboxes,
  menuCallback,
}) {
  const selectItemForEdit = (item) => {
    menuCallback("edit", item);
  };

  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        {showCheckboxes && (
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select</span>
              <input
                id={`check-account-${item.id}`}
                className="form-checkbox"
                type="checkbox"
                onChange={(e) => handleClick(e, item.id)}
                checked={isChecked}
              />
            </label>
          </div>
        )}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{item.date.substring(0, 7)}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{item.account.name}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {formatMonetaryValue(item.paidMarketing)}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {formatMonetaryValue(item.fullyLoaded)}
        </div>
      </td>
      <td>
        {/* Edit button */}
        <div className="text-center">
          <button
            className="p-1.5 flex-shrink-0 ml-2"
            onClick={() => selectItemForEdit({ ...item })}
          >
            <svg
              className="w-4 h-4 fill-current text-gray-500"
              viewBox="0 0 16 16"
            >
              <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
            </svg>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default MSTableItem;
