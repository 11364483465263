import moment from "moment";
import resolveConfig from "tailwindcss/resolveConfig";
const tailwindConfigFile = require("../css/tailwind.config");

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig(tailwindConfigFile);
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatMonetaryValue = (value) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);

export const formatPercentageValue = (value) => {
  return `${value.toFixed(2).toLocaleString()}%`;
};

export const formatNumber = (value) => {
  const parsed = parseFloat(value);
  if (Number.isInteger(parsed)) return parseInt(parsed).toLocaleString();
  if (!Number.isNaN(parsed)) return parsed.toFixed(2).toLocaleString();
  return value;
};

export const pluck = (obj, keys) => {
  const newObj = {};
  keys.forEach((key) => (newObj[key] = obj[key]));
  return newObj;
};

export const formatCohort = (value, sliceBy) => {
  let format = "YYYY-MM";
  switch (sliceBy) {
    case "week":
      format = "YYYY/WW";
      break;
    case "month":
      break;
    case "year":
      format = "YYYY";
      break;
    default:
      break;
  }
  return moment(value).format(format);
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(` `);
};
