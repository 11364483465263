import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import SettingsSidebar from "../partials/settings/SettingsSidebar";
import AccountProfile from "../partials/settings/AccountProfile";
import ContributionMargin from "../partials/settings/ContributionMargin";
import UserProfile from "../partials/settings/UserProfile";

function Settings() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                Account Settings{" "}
              </h1>
            </div>

            {/* Content */}
            <div className="bg-white shadow-lg rounded-sm mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <SettingsSidebar
                  activeTabIndex={activeTabIndex}
                  onTabChange={setActiveTabIndex}
                />
                {activeTabIndex === 0 && <UserProfile />}
                {activeTabIndex === 1 && <AccountProfile />}
                {activeTabIndex === 2 && <ContributionMargin />}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Settings;
