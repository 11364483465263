import React, { useState, useEffect } from "react";
import debounce from "lodash.debounce";

import Info from "../Info";
import Loader from "../components/Loader";
import LTVTableItem from "./LTVTableItem";

import { download } from "../../utils/XLXS";

function LTVTable({
  account,
  startDate,
  endDate,
  dataRetreiver,
  title,
  description,
  cacInput,
}) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cac, setCAC] = useState(0);

  const changeHandler = (e) => {
    try {
      setCAC(parseFloat(e.target.value));
    } catch (err) {
      console.log(err);
    }
  };

  const debouncedChangeHandler = debounce(changeHandler, 1000);

  const downloadFormatter = (tableData, name) => {
    const data = tableData.map((obj) => {
      const d = { ...obj, ...obj.values };
      delete d.values;
      return d;
    });
    download(data, name);
  };

  useEffect(() => {
    const fetchCalcData = async (cac) => {
      if (!account) return;
      setLoading(true);
      const { data } = await dataRetreiver(account, startDate, endDate, cac);
      setTableData(data);
      setLoading(false);
    };
    fetchCalcData(cac);
  }, [account, startDate, endDate, cac, dataRetreiver]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200 mt-4 overflow-x-auto">
      <header className="px-5 py-4 border-b border-gray-100 flex flex-row">
        <h2 className="font-semibold text-gray-800">{title}</h2>
        {description && (
          <Info className="ml-2" containerClassName="min-w-44">
            <div className="text-sm text-center">{description}</div>
          </Info>
        )}
        {cacInput && (
          <input
            id="name"
            className="form-input ml-auto flex flex self-end"
            type="text"
            placeholder="Enter CAC value"
            onChange={debouncedChangeHandler}
          />
        )}
        <button
          type="button"
          className={
            "btn bg-green hover:bg-green-dark text-white flex self-end " +
            (cacInput ? "ml-4" : "ml-auto")
          }
          style={{ right: "1rem", top: ".5rem" }}
          onClick={() =>
            downloadFormatter(
              tableData,
              title.toLowerCase().replaceAll(" ", "-")
            )
          }
        >
          Download
        </button>
      </header>
      {tableData.length > 0 && !loading && (
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Cohort</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">Customers</div>
                </th>
                {tableData.length > 0 &&
                  Object.keys(tableData[0].values).map((k, i) => {
                    return (
                      <th
                        key={k}
                        className={
                          "px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                        }
                      >
                        <div className="font-semibold text-center">{k}</div>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {tableData.map((x, i) => {
                return (
                  <LTVTableItem
                    key={i}
                    position={i}
                    item={x}
                    defaultValue={0.0}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <Loader visible={account && loading} />
    </div>
  );
}

export default LTVTable;
