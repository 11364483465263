import update from "immutability-helper";
import { getAxiosOrDefaultErrorMessage } from "../initializers/axios";
import { setQueryParams } from "../utils/QueryParams";
import { runInAction } from "mobx";

export function createStore(service) {
  return () => {
    return {
      filters: {},
      items: [],
      limit: 50,
      selectedItems: [],
      skip: 0,
      sortProperty: "id",
      sortDirection: "ASC",
      total: 0,
      async create(props) {
        try {
          const { data } = await service.create(props);
          return { data };
        } catch (err) {
          const error = getAxiosOrDefaultErrorMessage(err);
          return { error };
        }
      },
      async fetch() {
        const { data, meta } = await service.search(this.filters, {
          skip: this.skip,
          limit: this.limit,
          sortProperty: this.sortProperty,
          sortDirection: this.sortDirection,
        });

        setQueryParams({
          ...this.filters,
          skip: this.skip,
          limit: this.limit,
          sortProperty: this.sortProperty,
          sortDirection: this.sortDirection,
        });
        runInAction(() => {
          this.items = data || [];
          this.total = parseInt(meta.count || 0);
        });
        return { data, meta };
      },
      async remove() {
        try {
          const { data } = await service.remove(this.selectedItems);
          this.items = [
            ...this.items.filter((x) => !this.selectedItems.includes(x.id)),
          ];
          this.selectedItems = [];
          return { data };
        } catch (err) {
          const error = getAxiosOrDefaultErrorMessage(err);
          return { error };
        }
      },
      async update(id, props) {
        try {
          const { data } = await service.update(id, props);
          const idx = this.items.findIndex((x) => x.id === id);
          this.items = update(this.items, { [idx]: { $set: data } });
          return { data };
        } catch (err) {
          const error = getAxiosOrDefaultErrorMessage(err);
          return { error };
        }
      },
      setFilters(f) {
        this.filters = { ...f };
      },
      setLimit(l) {
        this.limit = l;
      },
      setSelectedItems(itemsArray) {
        this.selectedItems = [...itemsArray];
      },
      setSkip(s) {
        this.skip = s;
      },
      setSortProperty(s) {
        this.sortProperty = s;
      },
      setSortDirection(s) {
        this.sortDirection = s;
      },
    };
  };
}
