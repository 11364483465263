import { api } from "../initializers/axios";

const config = async (accountId) => {
  const { data } = await api.get("/order-items/config?accountId=" + accountId);
  return data;
};

const search = async (filters = {}, options = {}) => {
  const copy = { ...filters };
  if (copy.startDate) {
    copy.start = copy.startDate.format("YYYY-MM-DD");
    delete copy.startDate;
  }
  if (copy.endDate) {
    copy.end = copy.endDate.format("YYYY-MM-DD");
    delete copy.endDate;
  }
  const optionsStr = Object.keys(options).reduce((str, f) => {
    str = `${str}&${f}=${options[f]}`;
    return str;
  }, "?");

  const filterStr = Object.keys(copy).reduce((str, f) => {
    str = `${str}&${f}=${copy[f]}`;
    return str;
  }, "");
  const x = api.get(`/order-items${optionsStr}${filterStr}`);
  const { data, meta } = await x;
  return { data, meta };
};

const methods = {
  config,
  search,
};

export default methods;
