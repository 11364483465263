import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthImage from "../images/flatirons.jpeg";
import authServices from "../services/auth.services";
import { getQueryParams } from "../utils/QueryParams";
import { getAxiosOrDefaultErrorMessage } from "../initializers/axios";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [message, setMessage] = useState("");

  const sendPasswordReset = async (e) => {
    e.preventDefault();
    try {
      if (newPassword.length && newPassword === confirmPassword) {
        const { data } = await authServices.changePassword(token, newPassword);
        if (data) {
          setMessage(
            "Your password has been updated, you may now sign-in with the new password."
          );
        }
      } else {
        setMessage("Please enter matching passwords");
      }
    } catch (err) {
      setMessage(getAxiosOrDefaultErrorMessage(err));
    }
    return false;
  };

  useEffect(() => {
    const queryParams = getQueryParams();
    if (queryParams.token) {
      setToken(queryParams.token);
    }
  }, [setToken]);

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                Update your Password
              </h1>
              {message && (
                <div className="mt-5">
                  <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded mb-3">
                    <span className="text-sm">{message}</span>
                  </div>
                </div>
              )}
              {/* Form */}
              <form onSubmit={sendPasswordReset}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      New Password <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="new-password"
                      className="mt-1 block w-full rounded-md bg-gray-100 border-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Confirm Password <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="confirm-password"
                      className="mt-1 block w-full rounded-md bg-gray-100 border-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button className="btn bg-green hover:bg-green-dark text-white whitespace-nowrap">
                    Save
                  </button>
                </div>
              </form>
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Back to &nbsp;
                  <Link
                    className="font-medium text-green hover:text-green-dark"
                    to="/signin"
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;
