import React, { useEffect, useState } from "react";
import { Observer } from "mobx-react-lite";
import { useToasts } from "react-toast-notifications";
import accountServices from "../../services/account.services";
import { useUserStore } from "../../state/UserContext";

import AccountForm from "../accounts/AccountForm";

function AccountProfile() {
  const userStore = useUserStore();
  const [account, setAccount] = useState({});

  const { addToast } = useToasts();

  const cancelHandler = () => {
    setAccount({ ...account });
  };

  const submitHandler = (data) => {
    setAccount(data);
    addToast("Account updated!", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  useEffect(() => {
    const fetch = async () => {
      if (!userStore.user.accountId) return;
      const data = await accountServices.getById(userStore.user.accountId);
      setAccount(data.data);
    };
    fetch();
  }, [userStore, userStore.user]);

  return (
    <Observer>
      {() => (
        <AccountForm
          account={account}
          cancelHandler={cancelHandler}
          submitHandler={submitHandler}
        />
      )}
    </Observer>
  );
}

export default AccountProfile;
