import React from "react";
import exportServices from "../../services/export.services";

function ExportsTableItem({ item, handleClick, isChecked, showCheckboxes }) {
  const download = async (id) => {
    const { signedUrl } = await exportServices.download(id);
    window.open(signedUrl, "_blank");
  };

  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        {showCheckboxes && (
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select</span>
              <input
                id={`check-account-${item.id}`}
                className="form-checkbox"
                type="checkbox"
                onChange={(e) => handleClick(e, item.id)}
                checked={isChecked}
              />
            </label>
          </div>
        )}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{item.fileName}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left uppercase">{item.status}</div>
      </td>
      <td>
        {/* Download button */}
        <div className="text-center">
          {item.status === "ready" && (
            <button onClick={() => download(item.id)}>
              <span className="sr-only">Download</span>
              <svg
                className="w-4 h-4 flex-shrink-0 fill-current text-gray-400"
                viewBox="0 0 16 16"
              >
                <path d="M15 15H1a1 1 0 01-1-1V2a1 1 0 011-1h4v2H2v10h12V3h-3V1h4a1 1 0 011 1v12a1 1 0 01-1 1zM9 7h3l-4 4-4-4h3V1h2v6z" />
              </svg>
            </button>
          )}
        </div>
      </td>
    </tr>
  );
}

export default ExportsTableItem;
