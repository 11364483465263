import React, { useState } from "react";
import { Link } from "react-router-dom";

import AuthImage from "../images/flatirons.jpeg";

import authServices from "../services/auth.services";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const sendPasswordReset = async (e) => {
    e.preventDefault();
    try {
      const resp = await authServices.forgotPassword(email);
      if (resp === "OK") {
        setShowMessage(true);
      }
    } catch (err) {}
    return false;
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                Reset your Password
              </h1>
              {showMessage && (
                <div className="mt-5">
                  <div className="bg-green-100 text-green-600 px-3 py-2 rounded mb-3">
                    <span className="text-sm">
                      An password reset email has been sent to the address
                      provided
                    </span>
                  </div>
                </div>
              )}
              {/* Form */}
              <form onSubmit={sendPasswordReset}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="email"
                      className="mt-1 block w-full rounded-md bg-gray-100 border-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button className="btn bg-green hover:bg-green-dark text-white whitespace-nowrap">
                    Send Reset Link
                  </button>
                </div>
              </form>
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Back to &nbsp;
                  <Link
                    className="font-medium text-green hover:text-green-dark"
                    to="/signin"
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
        </div>
      </div>
    </main>
  );
}

export default ForgotPassword;
