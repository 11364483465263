import React, { useState } from "react";
import { useStore } from "../../state/AdminsContext";

function AdminForm({ submitHandler, cancelHandler }) {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const store = useStore();

  const handleCancel = () => {
    setError("");
    setEmail("");
    setPassword("");
    cancelHandler();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const { data, error } = await store.create({
      email,
      password,
    });

    if (error && error.length > 0) setError(error);
    else if (data && submitHandler) {
      setError("");
      setEmail("");
      setPassword("");
      submitHandler(data);
    }
    return false;
  };

  return (
    <form onSubmit={handleSave}>
      <div className="flex-grow">
        {/* Panel body */}
        <div className="p-6 space-y-6">
          {/* Business Profile */}
          <section>
            <h2 className="text-xl text-gray-800 font-bold mb-1">Add Admin</h2>
            <div className="text-sm">
              Add a new Admin capable of creating Users and Accounts
            </div>
            {error.length > 0 && (
              <div className="mt-5">
                <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                  <span className="text-sm">{error}</span>
                </div>
              </div>
            )}
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
              <div className="sm:w-1/2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="admin-email"
                >
                  Email
                </label>
                <input
                  id="admin-email"
                  className="form-input w-full"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="sm:w-1/2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="admin-password"
                >
                  Password
                </label>
                <input
                  id="admin-password"
                  className="form-input w-full"
                  type="text"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-gray-200">
            <div className="flex self-end">
              <button
                type="button"
                className="btn border-gray-200 hover:border-gray-300 text-gray-600"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn bg-green hover:bg-green-dark text-white ml-3"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </footer>
      </div>
    </form>
  );
}

export default AdminForm;
