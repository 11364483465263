import React from "react";
import moment from "moment";
import { useToasts } from "react-toast-notifications";

import { useStore } from "../../state/AdminsContext";
import { useUserStore } from "../../state/UserContext";

function AdminsTableItem(props) {
  let item = props.item;
  const { addToast } = useToasts();
  const store = useStore();
  const userStore = useUserStore();

  const updateSuper = async (id, isSuper) => {
    await store.update(id, { isSuper });
    addToast("Admin Updated!", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  return (
    <tr>
      {userStore.hasPermission(["MANAGE_ADMINS"]) && (
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select</span>
              <input
                id={`check-admin-${item.id}`}
                className="form-checkbox"
                type="checkbox"
                onChange={(e) => props.handleClick(e, item.id)}
                checked={props.isChecked}
              />
            </label>
          </div>
        </td>
      )}
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{item.email}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {moment(item.createdAt).format("MM/DD/yyyy")}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          <div
            className="form-switch focus-within:shadow-outline"
            style={{ margin: "0 auto" }}
          >
            <input
              type="checkbox"
              id={`sync-account-${item.id}`}
              className="sr-only"
              checked={item.isSuper}
              onChange={() => updateSuper(item.id, !item.isSuper)}
              disabled={
                !userStore.user || !userStore.hasPermission(["MANAGE_ADMINS"])
              }
            />
            <label className="bg-gray-400" htmlFor={`sync-account-${item.id}`}>
              <span className="bg-white shadow-sm" aria-hidden="true"></span>
              <span className="sr-only">Enable sync</span>
            </label>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default AdminsTableItem;
