import React from "react";
import { formatMonetaryValue } from "../../utils/Utils";

function CohortRevenueTableItem({ item }) {
  return (
    <tr>
      {Object.keys(item).map((k, i) => {
        return (
          <td
            className={
              (i % 2 === 0 ? "bg-gray-100 " : "") +
              "px-2 py-3 whitespace-nowrap"
            }
            key={i}
          >
            <div className={k === "cohort" ? "text-left" : "text-center"}>
              {k === "cohort" || k === "month"
                ? item[k]
                : item[k]
                ? formatMonetaryValue(item[k])
                : ""}
            </div>
          </td>
        );
      })}
    </tr>
  );
}

export default CohortRevenueTableItem;
