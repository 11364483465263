import React, { useState } from "react";

import { Observer } from "mobx-react-lite";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardFilters from "../partials/dashboard/DashboardFilters";

import CAC from "../partials/dashboard/CAC";
import CACFully from "../partials/dashboard/CACFully";
import CustomersByInterval from "../partials/dashboard/CustomersByInterval";
import AOV from "../partials/dashboard/AOV";

import { useStore } from "../state/DashboardContext";
import { useUserStore } from "../state/UserContext";

function CustomerAcquisitions() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const store = useStore();
  const userStore = useUserStore();
  store.setQueryParams();

  return (
    <Observer>
      {() => (
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Dashboard filters */}
                <DashboardFilters startDate endDate sliceBy accounts />

                {/* Cards */}
                <div className="grid grid-cols-12 gap-6">
                  <CAC
                    account={
                      store.accountId ||
                      (userStore.user && userStore.user.accountId)
                    }
                    startDate={store.startDate}
                    endDate={store.endDate}
                  />

                  <CACFully
                    account={
                      store.accountId ||
                      (userStore.user && userStore.user.accountId)
                    }
                    startDate={store.startDate}
                    endDate={store.endDate}
                  />

                  <CustomersByInterval
                    account={
                      store.accountId ||
                      (userStore.user && userStore.user.accountId)
                    }
                    startDate={store.startDate}
                    endDate={store.endDate}
                    sliceBy={store.sliceBy}
                  />
                  <AOV
                    account={
                      store.accountId ||
                      (userStore.user && userStore.user.accountId)
                    }
                    startDate={store.startDate}
                    endDate={store.endDate}
                    sliceBy={store.sliceBy}
                  />
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </Observer>
  );
}

export default CustomerAcquisitions;
