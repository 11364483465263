import React from "react";
import { useUserStore } from "../../state/UserContext";

function SettingsSidebar({ activeTabIndex, onTabChange }) {
  const userStore = useUserStore();

  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-gray-200 md:w-60 md:space-y-3">
      {/* Group 1 */}
      <div>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-3">
          Settings
        </div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <a
              className={
                "flex items-center px-2.5 py-2 rounded whitespace-nowrap " +
                (activeTabIndex === 0 ? "bg-green" : "")
              }
              href="#0"
              onClick={() => onTabChange(0)}
            >
              <svg
                className={
                  "w-4 h-4 flex-shrink-0 fill-current mr-2 " +
                  (activeTabIndex === 0 ? "text-white" : "text-gray-500")
                }
                viewBox="0 0 16 16"
              >
                <path d="M12.311 9.527c-1.161-.393-1.85-.825-2.143-1.175A3.991 3.991 0 0012 5V4c0-2.206-1.794-4-4-4S4 1.794 4 4v1c0 1.406.732 2.639 1.832 3.352-.292.35-.981.782-2.142 1.175A3.942 3.942 0 001 13.26V16h14v-2.74c0-1.69-1.081-3.19-2.689-3.733zM6 4c0-1.103.897-2 2-2s2 .897 2 2v1c0 1.103-.897 2-2 2s-2-.897-2-2V4zm7 10H3v-.74c0-.831.534-1.569 1.33-1.838 1.845-.624 3-1.436 3.452-2.422h.436c.452.986 1.607 1.798 3.453 2.422A1.943 1.943 0 0113 13.26V14z" />
              </svg>
              <span
                className={
                  "text-sm font-medium " +
                  (activeTabIndex === 0
                    ? "text-white"
                    : "text-gray-600 hover:text-gray-700")
                }
              >
                My Account
              </span>{" "}
            </a>
          </li>
          {userStore.hasPermission(["MANAGE_OWN_ACCOUNT"]) && (
            <li className="mr-0.5 md:mr-0 md:mb-0.5">
              <a
                className={
                  "flex items-center px-2.5 py-2 rounded whitespace-nowrap " +
                  (activeTabIndex === 1 ? "bg-green" : "")
                }
                href="#0"
                onClick={() => onTabChange(1)}
              >
                <svg
                  className={
                    "w-4 h-4 flex-shrink-0 fill-current mr-2 " +
                    (activeTabIndex === 1 ? "text-white" : "text-gray-500")
                  }
                  viewBox="0 0 16 16"
                >
                  <path d="M3.414 2L9 7.586V16H7V8.414l-5-5V6H0V1a1 1 0 011-1h5v2H3.414zM15 0a1 1 0 011 1v5h-2V3.414l-3.172 3.172-1.414-1.414L12.586 2H10V0h5z" />
                </svg>
                <span
                  className={
                    "text-sm font-medium " +
                    (activeTabIndex === 1
                      ? "text-white"
                      : "text-gray-600 hover:text-gray-700")
                  }
                >
                  Shopify Credentials
                </span>
              </a>
            </li>
          )}
          {userStore.hasPermission(["MANAGE_CM"]) &&
            userStore.user &&
            userStore.user.accountId && (
              <li className="mr-0.5 md:mr-0 md:mb-0.5">
                <a
                  className={
                    "flex items-center px-2.5 py-2 rounded whitespace-nowrap " +
                    (activeTabIndex === 2 ? "bg-green" : "")
                  }
                  href="#0"
                  onClick={() => onTabChange(2)}
                >
                  <svg
                    className={
                      "w-4 h-4 flex-shrink-0 fill-current mr-2 " +
                      (activeTabIndex === 2 ? "text-white" : "text-gray-500")
                    }
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.414 2L9 7.586V16H7V8.414l-5-5V6H0V1a1 1 0 011-1h5v2H3.414zM15 0a1 1 0 011 1v5h-2V3.414l-3.172 3.172-1.414-1.414L12.586 2H10V0h5z" />
                  </svg>
                  <span
                    className={
                      "text-sm font-medium " +
                      (activeTabIndex === 2
                        ? "text-white"
                        : "text-gray-600 hover:text-gray-700")
                    }
                  >
                    Contribution Margin
                  </span>
                </a>
              </li>
            )}
        </ul>
      </div>
    </div>
  );
}

export default SettingsSidebar;
