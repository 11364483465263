import React, { useState, useEffect } from "react";
import { focusHandling } from "cruip-js-toolkit";
import CM from "./CMTableItem";

import { useStore } from "../../state/CMContext";

function CMTable({
  list,
  selectedItems,
  onSelectedChange,
  showCheckboxes,
  menuCallback,
}) {
  const [selectAll, setSelectAll] = useState(false);

  const store = useStore();

  useEffect(() => {
    focusHandling("outline");
  }, [list]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    onSelectedChange(list.map((li) => li.id));
    if (selectAll) {
      onSelectedChange([]);
    }
  };

  const handleClick = (e, id) => {
    const { checked } = e.target;
    setSelectAll(false);
    if (!checked) {
      onSelectedChange(selectedItems.filter((item) => item !== id));
    } else {
      onSelectedChange([...selectedItems, id]);
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800">
          All Contribution Margins{" "}
          <span className="text-gray-400 font-medium">{store.total}</span>
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  {showCheckboxes && (
                    <div className="flex items-center">
                      <label className="inline-flex">
                        <span className="sr-only">Select all</span>
                        <input
                          className="form-checkbox"
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </label>
                    </div>
                  )}
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Account</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">
                    Contribution Margin %
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"></th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {list.map((a) => {
                return (
                  <CM
                    key={a.id}
                    id={a.id}
                    handleClick={handleClick}
                    isChecked={selectedItems.includes(a.id)}
                    item={a}
                    showCheckboxes={showCheckboxes}
                    menuCallback={menuCallback}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CMTable;
