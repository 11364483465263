import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import jwt from "jsonwebtoken";
import Cookies from "../utils/Cookies";

const apiDefaults = {
  timeout: 300000,
};

const api = axios.create({
  ...apiDefaults,
  baseURL: process.env.REACT_APP_API_URL,
});

const getAxiosOrDefaultErrorMessage = (error) => {
  const { message } =
    error.response && error.response.data && error.response.data.message
      ? error.response.data
      : error;

  return message;
};

const isExpired = () => {
  const token = jwt.decode(Cookies.get("REFRESH_TOKEN") || null);
  if (!token || Date.now() >= token.exp * 1000) {
    return true;
  }
  return false;
};

const onResponseSuccess = (result) => {
  const toAppend = { meta: {} };
  if (result.headers["x-total-count"])
    toAppend.meta.count = result.headers["x-total-count"];
  if (result.headers["content-disposition"])
    toAppend.meta.fileName = result.headers["content-disposition"];
  return { ...result, ...toAppend };
};

const refreshAuthLogic = (failedRequest) => {
  if (
    isExpired() &&
    !["/signin", "/reset-password"].includes(window.location.pathname)
  )
    window.location = "/signin";
  return api
    .post(`${process.env.REACT_APP_API_URL}/auth/access`, {
      refreshToken: Cookies.get("REFRESH_TOKEN"),
    })
    .then((tokenRefreshResponse) => {
      const newToken = tokenRefreshResponse.data;
      Cookies.set("ACCESS_TOKEN", newToken, 2);
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + newToken;
      return Promise.resolve();
    })
    .catch((err) => {
      if (
        err &&
        err.response &&
        err.response.status === 401 &&
        !["/signin", "/reset-password"].includes(window.location.pathname)
      ) {
        window.location = "/signin";
      }
    });
};

// Obtain the fresh token each time the function is called
const getAccessToken = () => {
  return Cookies.get("ACCESS_TOKEN");
};

// Use interceptor to inject the token to requests
api.interceptors.request.use((request) => {
  request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  return request;
});

createAuthRefreshInterceptor(api, refreshAuthLogic, {
  statusCodes: [401],
});

api.interceptors.response.use(onResponseSuccess);

export { api, getAxiosOrDefaultErrorMessage };
