import { api } from "../initializers/axios";

const getMe = async () => {
  const { data } = await api.get("/me");
  return { data };
};

const update = async (props) => {
  const { data } = await api.put("/me", props);
  return { data };
};

const methods = {
  getMe,
  update,
};

export default methods;
