import React from "react";
import { createStore } from "./listStore";
import { useLocalObservable } from "mobx-react-lite";
import accountServices from "../services/account.services";

const AccountsContext = React.createContext(null);

export const AccountsProvider = ({ children }) => {
  const accountsStore = useLocalObservable(
    createStore(accountServices, { id: null, name: "", secret: "" })
  );

  return (
    <AccountsContext.Provider value={accountsStore}>
      {children}
    </AccountsContext.Provider>
  );
};

export const useStore = () => React.useContext(AccountsContext);
