import React, { useState } from "react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "../../css/react-date-overrides.css";

function Datepicker({ value, onChange, disableDateFunc }) {
  const [focused, setFocused] = useState(false);

  return (
    <div className="relative">
      <SingleDatePicker
        appendToBody={true}
        displayFormat={"yyyy-MM-DD"}
        isOutsideRange={() => false}
        regular
        date={value} // momentPropTypes.momentObj or null
        onDateChange={(date) => onChange(date)} // PropTypes.func.isRequired
        focused={focused} // PropTypes.bool
        onFocusChange={(f) => {
          setFocused(f.focused);
        }} // PropTypes.func.isRequired
        id="date_picker" // PropTypes.string.isRequired,
        isDayBlocked={disableDateFunc}
      />
      <div className="absolute inset-0 right-auto flex items-center pointer-events-none"></div>
    </div>
  );
}

export default Datepicker;
