import { api } from "../initializers/axios";

const create = async ({ accountId, email, password }) => {
  const { data } = await api.post("/users", { accountId, email, password });
  return { data };
};

const remove = async (ids) => {
  const { data } = await api.post("/users/delete", { ids });
  return { data };
};

const search = async (filters = {}, options = {}) => {
  const optionsStr = Object.keys(options).reduce((str, f) => {
    str = `${str}&${f}=${options[f]}`;
    return str;
  }, "?");

  const filterStr = Object.keys(filters).reduce((str, f) => {
    str = `${str}&${f}=${filters[f]}`;
    return str;
  }, "");
  const x = api.get(`/users${optionsStr}${filterStr}`);
  const { data, meta } = await x;
  return { data, meta };
};

const update = async (id, props) => {
  const { data } = await api.put(`/users/${id}`, props);
  return { data };
};

const methods = {
  create,
  remove,
  search,
  update,
};

export default methods;
