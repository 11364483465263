import React from "react";
import { formatMonetaryValue } from "../../utils/Utils";

function CACLTVTableItem({ item, position }) {
  return (
    <tr className={position % 2 !== 0 ? "bg-gray-100" : ""}>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{item.cohort}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">{item.customers}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">{item.initialOrderCount}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {formatMonetaryValue(item.initialRevenue)}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">{item.totalOrderCount}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {formatMonetaryValue(item.totalRevenue)}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {parseFloat(item.nspcAll)
            ? formatMonetaryValue(item.nspcAll)
            : "$0.00"}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {parseFloat(item.nspc0) ? formatMonetaryValue(item.nspc0) : "$0.00"}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {parseFloat(item.nspc6) ? formatMonetaryValue(item.nspc6) : "$0.00"}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {parseFloat(item.nspc12) ? formatMonetaryValue(item.nspc12) : "$0.00"}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {parseFloat(item.nspc24) ? formatMonetaryValue(item.nspc24) : "$0.00"}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {parseFloat(item.nspc36) ? formatMonetaryValue(item.nspc36) : "$0.00"}
        </div>
      </td>
    </tr>
  );
}

export default CACLTVTableItem;
