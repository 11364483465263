import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Observer } from "mobx-react-lite";
import Cookies from "../../utils/Cookies";
import Transition from "../../utils/Transition";

import { useUserStore } from "../../state/UserContext";
import meServices from "../../services/me.services";

function UserMenu() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const userStore = useUserStore();
  const trigger = useRef(null);
  const dropdown = useRef(null);

  const signOut = () => {
    Cookies.delete("ACCESS_TOKEN");
    Cookies.delete("REFRESH_TOKEN");
    setDropdownOpen(false);
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    async function fetchData() {
      if (!userStore.user.id && !fetching) {
        setFetching(true);
        const { data } = await meServices.getMe();
        if (data) {
          userStore.setUser(data);
        }
        setFetching(false);
      }
    }
    fetchData();
  }, [fetching, userStore, userStore.user]);

  return (
    <Observer>
      {() => (
        <div className="relative inline-flex">
          <button
            ref={trigger}
            className="inline-flex justify-center items-center group"
            aria-haspopup="true"
            onClick={() => setDropdownOpen(!dropdownOpen)}
            aria-expanded={dropdownOpen}
          >
            <div className="flex items-center truncate">
              <span className="truncate ml-2 text-sm font-medium group-hover:text-gray-800">
                <span className="text-xl pr-3">&#10095;</span>
                {userStore.user && userStore.user.email}
              </span>
              <svg
                className="w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400"
                viewBox="0 0 12 12"
              >
                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
              </svg>
            </div>
          </button>

          <Transition
            className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
            show={dropdownOpen}
            enter="transition ease-out duration-200 transform"
            enterStart="opacity-0 -translate-y-2"
            enterEnd="opacity-100 translate-y-0"
            leave="transition ease-out duration-200"
            leaveStart="opacity-100"
            leaveEnd="opacity-0"
          >
            <div
              ref={dropdown}
              onFocus={() => setDropdownOpen(true)}
              onBlur={() => setDropdownOpen(false)}
            >
              <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
                <div className="text-xs text-gray-500 italic">Account</div>
                <div className="font-medium text-gray-800">
                  {(userStore.user.account && userStore.user.account.name) ||
                    "Admin"}
                </div>
              </div>
              <ul>
                <li>
                  <Link
                    className="font-medium text-sm text-green hover:text-green-dark flex items-center py-1 px-3"
                    to="/settings"
                  >
                    Settings
                  </Link>
                </li>
                <li>
                  <Link
                    className="font-medium text-sm text-green hover:text-green-dark flex items-center py-1 px-3"
                    to="/signin"
                    onClick={() => signOut()}
                  >
                    Sign Out
                  </Link>
                </li>
              </ul>
            </div>
          </Transition>
        </div>
      )}
    </Observer>
  );
}

export default UserMenu;
