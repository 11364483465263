import React, { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";
import Info from "../Info";
import Loader from "../components/Loader";

// Import utilities
import { tailwindConfig } from "../../utils/Utils";

import calcServices from "../../services/calc.services";

function NewVsReturningRevInterval({ account, startDate, endDate, sliceBy }) {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const defaultChartData = {
      labels: [],
      datasets: [
        {
          label: "New",
          data: [],
          backgroundColor: tailwindConfig().theme.colors.green["DEFAULT"],
          barPercentage: 0.66,
          categoryPercentage: 0.66,
        },
        {
          label: "Returning",
          data: [],
          backgroundColor: tailwindConfig().theme.colors.green["light"],
          barPercentage: 0.66,
          categoryPercentage: 0.66,
        },
      ],
    };

    const fetchCalcData = async (startDate, endDate) => {
      if (!account) return;
      setLoading(true);
      const { data } = await calcServices.newVsReturningRevInterval(
        account,
        sliceBy,
        startDate,
        endDate
      );
      defaultChartData.labels = data.new.map((x) => x.cohort);
      defaultChartData.datasets[0].data = data.new.map((x) => {
        const found = data.returning.find((r) => r.cohort === x.cohort);
        return (
          (parseFloat(x.total) /
            (parseFloat(x.total) + parseFloat(found ? found.total : 0))) *
          100
        );
      });
      defaultChartData.datasets[1].data = data.new.map((x) => {
        const found = data.returning.find((r) => r.cohort === x.cohort);
        return (
          (parseFloat(found ? found.total : 0) /
            (parseFloat(found ? found.total : 0) + parseFloat(x.total))) *
          100
        );
      });

      setChartData({ ...defaultChartData });
      setLoading(false);
    };
    fetchCalcData(startDate, endDate);
  }, [account, sliceBy, startDate, endDate]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">
          New vs. Returning Sales by Week/Month/Year&nbsp;&nbsp;
        </h2>
        <Info clafromssName="ml-2" containerClassName="min-w-44">
          <div className="text-sm text-center">
            % of Sales by week/month/year for new vs returning customers
          </div>
        </Info>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      {chartData && chartData.labels && !loading && (
        <BarChart
          data={chartData}
          width={595}
          height={248}
          formatType={"percentage"}
          timeOpts={{
            unit: sliceBy,
          }}
          stacked
        />
      )}
      <Loader visible={account && loading} />
    </div>
  );
}

export default NewVsReturningRevInterval;
