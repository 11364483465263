import React from "react";
import { createStore } from "./listStore";
import { useLocalObservable } from "mobx-react-lite";
import cmServices from "../services/contribution-margin.services";

const CMContext = React.createContext(null);

export const CMProvider = ({ children }) => {
  const store = useLocalObservable(createStore(cmServices));

  return <CMContext.Provider value={store}>{children}</CMContext.Provider>;
};

export const useStore = () => React.useContext(CMContext);
