import React, { useState, useEffect } from "react";
import CACLTVTableItem from "./CACLTVTableItem";
import Loader from "../components/Loader";
import calcServices from "../../services/calc.services";
import { download } from "../../utils/XLXS";

function CACLTVTable({ account, startDate, endDate }) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCalcData = async (startDate, endDate) => {
      if (!account) return;
      setLoading(true);
      const { data } = await calcServices.revTable(
        account,
        "month",
        startDate,
        endDate
      );

      setTableData(data);
      setLoading(false);
    };
    fetchCalcData(startDate, endDate);
  }, [account, startDate, endDate]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200 mt-4 overflow-x-auto">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center relative">
        <h2 className="font-semibold text-gray-800">Total Net Sales</h2>
        <button
          type="button"
          className="absolute btn bg-green hover:bg-green-dark text-white ml-3"
          style={{ right: "1rem", top: ".5rem" }}
          onClick={() => download(tableData, "calc-ltv-data")}
        >
          Download
        </button>
      </header>
      {tableData.length > 0 && !loading && (
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Cohort</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">Customers</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">
                    Initial Orders
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">Initial Sales</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">Total Orders</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">Total Sales</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">NSPC(All)</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">NSPC(M0)</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">NSPC(M6)</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">NSPC(M12)</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">NSPC(M24)</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">NSPC(M36)</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {tableData.map((x, i) => {
                return <CACLTVTableItem key={x.cohort} item={x} position={i} />;
              })}
            </tbody>
          </table>
        </div>
      )}
      <Loader visible={account && loading} />
    </div>
  );
}

export default CACLTVTable;
