import moment from "moment";
import { getQueryParams, setQueryParams } from "../utils/QueryParams";

export function createStore(diffMonths = 2, sliceByVal = "month") {
  const { accountId, startDate, endDate, sliceBy } = getQueryParams();
  return {
    accountId: accountId || null,
    startDate: startDate
      ? moment.utc(startDate)
      : moment.utc().subtract(diffMonths, "month"),
    endDate: endDate ? moment.utc(endDate) : moment.utc().subtract(1, "day"),
    sliceBy: sliceBy || sliceByVal,
    setAccountId(id) {
      this.accountId = id;
      setQueryParams(this);
    },
    setEndDate(date) {
      this.endDate = moment.utc(date);
      setQueryParams(this);
    },
    setStartDate(date) {
      this.startDate = moment.utc(date);
      setQueryParams(this);
    },
    setSliceBy(val) {
      this.sliceBy = val;
      setQueryParams(this);
    },
    setQueryParams() {
      setQueryParams(this);
    },
  };
}
