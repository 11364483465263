import React, { useState } from "react";
import { Observer } from "mobx-react-lite";
import { useUserStore } from "../state/UserContext";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardFilters from "../partials/dashboard/DashboardFilters";
import calcServices from "../services/calc.services";

import { useStore } from "../state/DashboardContext";
import LTVTable from "../partials/dashboard/LTVTable";

function Forecasting() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const store = useStore();
  const userStore = useUserStore();
  store.setQueryParams();

  const fetchCMLTVtoCACPaid = async (account, startDate, endDate, cac) => {
    return await calcServices.ltvToCac(
      account,
      startDate,
      endDate,
      "paid",
      null,
      cac
    );
  };

  const fetchCMLTVtoCACFully = async (account, startDate, endDate, cac) => {
    return await calcServices.ltvToCac(
      account,
      startDate,
      endDate,
      "fully",
      null,
      cac
    );
  };

  const fetchLTVtoCACPaid = async (account, startDate, endDate, cac) => {
    return await calcServices.ltvToCac(
      account,
      startDate,
      endDate,
      "paid",
      1,
      cac
    );
  };

  const fetchLTVtoCACFully = async (account, startDate, endDate, cac) => {
    return await calcServices.ltvToCac(
      account,
      startDate,
      endDate,
      "fully",
      1,
      cac
    );
  };

  return (
    <Observer>
      {() => (
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Dashboard filters */}
                <DashboardFilters startDate endDate accounts />

                {/* Cards */}

                <LTVTable
                  account={
                    store.accountId ||
                    (userStore.user && userStore.user.accountId)
                  }
                  startDate={store.startDate}
                  endDate={store.endDate}
                  dataRetreiver={fetchLTVtoCACPaid}
                  title={"Lifetime Contribution Margin / Actual Paid CAC"}
                  cacInput={true}
                />
                <LTVTable
                  account={
                    store.accountId ||
                    (userStore.user && userStore.user.accountId)
                  }
                  startDate={store.startDate}
                  endDate={store.endDate}
                  dataRetreiver={fetchLTVtoCACFully}
                  title={
                    "Lifetime Contribution Margin / Actual Fully Loaded CAC"
                  }
                  cacInput={true}
                />

                <LTVTable
                  account={
                    store.accountId ||
                    (userStore.user && userStore.user.accountId)
                  }
                  startDate={store.startDate}
                  endDate={store.endDate}
                  dataRetreiver={fetchCMLTVtoCACPaid}
                  title={"Contribution Margin LTV to CAC Paid"}
                  cacInput={true}
                />
                <LTVTable
                  account={
                    store.accountId ||
                    (userStore.user && userStore.user.accountId)
                  }
                  startDate={store.startDate}
                  endDate={store.endDate}
                  dataRetreiver={fetchCMLTVtoCACFully}
                  title={"Contribution Margin LTV to CAC Fully"}
                  cacInput={true}
                />
              </div>
            </main>
          </div>
        </div>
      )}
    </Observer>
  );
}

export default Forecasting;
