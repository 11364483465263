import React, { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";
import Info from "../Info";
import Loader from "../components/Loader";

// Import utilities
import { tailwindConfig } from "../../utils/Utils";

import calcServices from "../../services/calc.services";

function OrderFrequency({ account, startDate, endDate }) {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const defaultChartData = {
      labels: [],
      datasets: [
        {
          label: "% of customers",
          data: [],
          backgroundColor: tailwindConfig().theme.colors.green["DEFAULT"],
          hoverBackgroundColor: tailwindConfig().theme.colors.green["dark"],
          barPercentage: 0.66,
          categoryPercentage: 0.66,
        },
      ],
    };

    const fetchCalcData = async (startDate, endDate) => {
      if (!account) return;
      setLoading(true);
      const { data } = await calcServices.orderFrequency(
        account,
        startDate,
        endDate
      );
      data.groups = data.groups.sort(
        (a, b) => parseInt(a.frequency) - parseInt(b.frequency)
      );
      defaultChartData.labels = data.groups.map((x) => parseInt(x.frequency));
      defaultChartData.datasets[0].data = data.groups.map(
        (x) => parseFloat(x.count / data.total) * 100
      );
      setChartData({ ...defaultChartData });
      setLoading(false);
    };
    fetchCalcData(startDate, endDate);
  }, [account, startDate, endDate]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">
          Lifetime Order Distribution
        </h2>
        <Info className="ml-2" containerClassName="min-w-44">
          <div className="text-sm text-center">
            % of Customers that have Ordered X number of times
          </div>
        </Info>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      {chartData && chartData.labels && !loading && (
        <BarChart
          data={chartData}
          width={595}
          height={248}
          formatType={"percentage"}
        />
      )}
      <Loader visible={account && loading} />
    </div>
  );
}

export default OrderFrequency;
