import React, { useEffect, useState } from "react";
import debounce from "lodash.debounce";

import Select from "../actions/Select";
import accountServices from "../../services/account.services";
import { useStore } from "../../state/UsersContext";
import { useUserStore } from "../../state/UserContext";

function UserForm({ submitHandler, cancelHandler }) {
  const [error, setError] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accountId, setAccountId] = useState("");

  const store = useStore();
  const userStore = useUserStore();

  const handleSave = async (e) => {
    e.preventDefault();

    const { data, error } = await store.create({
      accountId: userStore.user.accountId || accountId,
      email,
      password,
    });

    if (error && error.length > 0) setError(error);
    else if (data && submitHandler) {
      setError("");
      setAccountId("");
      setEmail("");
      setPassword("");
      submitHandler(data);
    }
    return false;
  };

  const handleSelect = (account) => {
    setAccountId(account.id);
  };

  useEffect(() => {
    const fetchAccounts = debounce(async () => {
      const { data } = await accountServices.search({
        skip: 0,
        limit: 1000,
        sortProperty: "name",
      });
      setAccounts(data);
    }, 250);
    fetchAccounts();
  }, []);

  return (
    <form onSubmit={handleSave}>
      <div className="flex-grow">
        {/* Panel body */}
        <div className="p-6 space-y-6">
          {/* Business Profile */}
          <section>
            <h2 className="text-xl text-gray-800 font-bold mb-1">
              {"Add User"}
            </h2>
            <div className="text-sm">
              Add a new user capable of accessing Dashboards and Charts
            </div>
            {error.length > 0 && (
              <div className="mt-5">
                <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                  <span className="text-sm">{error}</span>
                </div>
              </div>
            )}
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
              <div className="sm:w-1/2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="user-email"
                >
                  Email
                </label>
                <input
                  id="user-email"
                  className="form-input w-full"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="sm:w-1/2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="user-password"
                >
                  Password
                </label>
                <input
                  id="user-password"
                  className="form-input w-full"
                  type="text"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </section>
          {userStore.user.isAdmin && (
            <section>
              <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                <div className="w-full">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="password"
                  >
                    Account
                  </label>
                  <Select
                    options={accounts}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={handleSelect}
                    value={accounts.filter(
                      (a) => parseInt(a.id) === parseInt(accountId)
                    )}
                  />
                </div>
              </div>
            </section>
          )}
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-gray-200">
            <div className="flex self-end">
              <button
                type="button"
                className="btn border-gray-200 hover:border-gray-300 text-gray-600"
                onClick={cancelHandler}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn bg-green hover:bg-green-dark text-white ml-3"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </footer>
      </div>
    </form>
  );
}

export default UserForm;
