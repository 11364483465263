import React, { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import Select from "../actions/Select";
import accountServices from "../../services/account.services";
import { useStore } from "../../state/CMContext";
import { useUserStore } from "../../state/UserContext";

function CMForm({ item, submitHandler, cancelHandler }) {
  const [error, setError] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [margin, setMargin] = useState(1);
  const [accountId, setAccountId] = useState("");

  const store = useStore();
  const userStore = useUserStore();

  const handleSave = async (e, keepOpen) => {
    e.preventDefault();
    let data,
      error = null;
    if (item && item.id) {
      const updated = await store.update(item.id, {
        margin,
      });
      data = updated.data;
      error = updated.error;
    } else {
      const created = await store.create({
        accountId,
        margin,
      });
      data = created.data;
      error = created.error;
    }
    if (error && error.length > 0) {
      setError(error);
    } else if (data && submitHandler) {
      setError("");
      setMargin(0.0);
      if (!keepOpen) {
        setAccountId(null);
        submitHandler(data);
      }
    }
    return false;
  };

  const fetchAccounts = debounce(async () => {
    const { data } = await accountServices.search({
      skip: 0,
      limit: 1000,
      sortProperty: "name",
    });
    setAccounts(data);
  }, 250);

  const handleSelect = (account) => {
    setAccountId(account.id);
  };

  useEffect(() => {
    if (item.id) {
      setMargin(item.margin);
      setAccountId(item.accountId);
    } else {
      setAccountId(userStore.user.accountId);
    }
  }, [item, userStore.user.accountId]);

  const isAdmin = userStore.user && userStore.user.isAdmin;

  useEffect(() => {
    if (isAdmin) fetchAccounts();
  }, [isAdmin, fetchAccounts]);

  return (
    <form>
      <div className="flex-grow">
        {/* Panel body */}
        <div className="p-6 space-y-6">
          {/* Business Profile */}
          <section>
            <h2 className="text-xl text-gray-800 font-bold mb-1">
              {item && item.id ? "Edit " : "Add "} Contribution Margin
            </h2>
            <div className="text-sm">
              Enter the contribution margin for {isAdmin ? "an" : "your"}{" "}
              account
            </div>
            {error.length > 0 && (
              <div className="mt-5">
                <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                  <span className="text-sm">{error}</span>
                </div>
              </div>
            )}
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
              {isAdmin && (
                <div className="sm:w-1/2">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="password"
                  >
                    Account
                  </label>
                  <Select
                    options={accounts}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={handleSelect}
                    value={accounts.filter(
                      (a) => parseInt(a.id) === parseInt(accountId)
                    )}
                  />
                </div>
              )}
              <div className="sm:w-1/2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="ms_paid_marketing"
                >
                  Margin %
                </label>
                <input
                  id="ms_paid_marketing"
                  className="form-input w-full"
                  type="text"
                  value={margin}
                  onChange={(e) => setMargin(e.target.value)}
                />
              </div>
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-gray-200">
            <div className="flex self-end">
              <button
                type="button"
                className="btn border-gray-200 hover:border-gray-300 text-gray-600"
                onClick={cancelHandler}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn bg-green hover:bg-green-dark text-white ml-3"
                onClick={(e) => handleSave(e, false)}
              >
                Save
              </button>
            </div>
          </div>
        </footer>
      </div>
    </form>
  );
}

export default CMForm;
