import XLSX from "xlsx";
import { saveAs } from "file-saver";

export const download = (tableData, fileName) => {
  try {
    var wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(tableData);
    XLSX.utils.book_append_sheet(wb, ws, "Overview");
    const wopts = { bookType: "xlsx", bookSST: false, type: "array" };

    const wbout = XLSX.write(wb, wopts);
    /* the saveAs call downloads a file on the local machine */
    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      `${fileName}-${Date.now()}.xlsx`
    );
  } catch (err) {
    console.error(err);
  }
};

export const downloadFormatter = (data, name) => {
  data.map((x) => {
    Object.keys(x).forEach((k) => {
      try {
        let val = x[k]
          .replaceAll("%", "")
          .replaceAll("$", "")
          .replaceAll(",", "");
        val = parseFloat(val);
        if (typeof val == "number" && !isNaN(val)) {
          x[k] = val;
        }
      } catch (err) {}
    });
    return x;
  });
  download(data, name);
};
