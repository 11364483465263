import React, { useState } from "react";
import { Observer } from "mobx-react-lite";
import { useUserStore } from "../state/UserContext";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardFilters from "../partials/dashboard/DashboardFilters";
import AOVVs from "../partials/dashboard/AOVVs";
import CohortOrderCountsTable from "../partials/dashboard/CohortOrderCountsTable";
import CohortRevenueTable from "../partials/dashboard/CohortRevenueTable";
import CohortShipRetentionTable from "../partials/dashboard/CohortShipRetentionTable";
import CumulativeNetRev from "../partials/dashboard/CumulativeNetRev";
import CustomerRetention from "../partials/dashboard/CustomerRetention";
import NewVsReturningRev from "../partials/dashboard/NewVsReturningRev";
import NewVsReturningRevInterval from "../partials/dashboard/NewVsReturningRevInterval";
import OrderFrequency from "../partials/dashboard/OrderFrequency";

import { useStore } from "../state/DashboardContext";

function Retention() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const store = useStore();
  const userStore = useUserStore();
  store.setQueryParams();

  return (
    <Observer>
      {() => (
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Dashboard filters */}
                <DashboardFilters startDate endDate sliceBy accounts />

                {/* Cards */}
                <div className="grid grid-cols-12 gap-6">
                  <OrderFrequency
                    account={
                      store.accountId ||
                      (userStore.user && userStore.user.accountId)
                    }
                    startDate={store.startDate}
                    endDate={store.endDate}
                  />
                  <NewVsReturningRev
                    account={
                      store.accountId ||
                      (userStore.user && userStore.user.accountId)
                    }
                    startDate={store.startDate}
                    endDate={store.endDate}
                  />
                  <CustomerRetention
                    account={
                      store.accountId ||
                      (userStore.user && userStore.user.accountId)
                    }
                    startDate={store.startDate}
                    endDate={store.endDate}
                    sliceBy={store.sliceBy}
                  />
                  <NewVsReturningRevInterval
                    account={
                      store.accountId ||
                      (userStore.user && userStore.user.accountId)
                    }
                    startDate={store.startDate}
                    endDate={store.endDate}
                    sliceBy={store.sliceBy}
                  />
                  <AOVVs
                    account={
                      store.accountId ||
                      (userStore.user && userStore.user.accountId)
                    }
                    startDate={store.startDate}
                    endDate={store.endDate}
                    sliceBy={store.sliceBy}
                  />
                  <CumulativeNetRev
                    account={
                      store.accountId ||
                      (userStore.user && userStore.user.accountId)
                    }
                    startDate={store.startDate}
                    endDate={store.endDate}
                    sliceBy={store.sliceBy}
                  />
                </div>
                <CohortOrderCountsTable
                  account={
                    store.accountId ||
                    (userStore.user && userStore.user.accountId)
                  }
                  startDate={store.startDate}
                  endDate={store.endDate}
                  sliceBy={store.sliceBy}
                />
                <CohortShipRetentionTable
                  account={
                    store.accountId ||
                    (userStore.user && userStore.user.accountId)
                  }
                  startDate={store.startDate}
                  endDate={store.endDate}
                  sliceBy={store.sliceBy}
                />
                <CohortRevenueTable
                  account={
                    store.accountId ||
                    (userStore.user && userStore.user.accountId)
                  }
                  startDate={store.startDate}
                  endDate={store.endDate}
                  sliceBy={store.sliceBy}
                />
              </div>
            </main>
          </div>
        </div>
      )}
    </Observer>
  );
}

export default Retention;
