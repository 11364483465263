const Cookies = {
  delete(name) {
    const domain =
      window.location.hostname === "localhost" ? "" : ";domain=.solidratio.app";
    document.cookie = `${name}=${domain};Max-Age=-99999999`;
  },
  get(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  set(name, value, days = 1, subDomain = false, isSecure = true) {
    const isHTTPS = window.location.protocol === "https:";
    let expires = "";
    const domain =
      window.location.hostname === "localhost" ? "" : ";domain=.solidratio.app";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `;expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ""}${domain}${expires};path=/;${
      isSecure && isHTTPS ? "secure" : ""
    }`;
  },
};

export default Cookies;
