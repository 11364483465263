import React, { useEffect, useState } from "react";
import { useStore } from "../../state/AccountsContext";
import { useUserStore } from "../../state/UserContext";

function AccountForm({ account, submitHandler, cancelHandler }) {
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [secret, setSecret] = useState("");
  const [username, setUsername] = useState("");
  const [amazonClientId, setAmazonClientId] = useState("");
  const [amazonClientSecret, setAmazonClientSecret] = useState("");
  const [amazonMarketplaceIds, setAmazonMarketplaceIds] = useState("");
  const [amazonRefreshToken, setAmazonRefreshToken] = useState("");

  const store = useStore();
  const userStore = useUserStore();

  const handleCancel = () => {
    setError("");
    setName("");
    setUsername("");
    setSecret("");
    setAmazonClientId("");
    setAmazonClientSecret("");
    setAmazonMarketplaceIds("");
    setAmazonRefreshToken("");
    cancelHandler();
  };
  const handleSave = async (e) => {
    e.preventDefault();
    let data,
      error = null;
    if (account.id) {
      const updated = await store.update(account.id, {
        name,
        secret,
        amazonClientId,
        amazonClientSecret,
        amazonMarketplaceIds,
        amazonRefreshToken,
      });
      data = updated.data;
      error = updated.error;
    } else {
      const created = await store.create({
        name,
        secret,
        username,
        amazonClientId,
        amazonClientSecret,
        amazonMarketplaceIds,
        amazonRefreshToken,
      });
      data = created.data;
      error = created.error;
    }
    if (error && error.length > 0) setError(error);
    else if (data && submitHandler) {
      setError("");
      setName("");
      setSecret("");
      setUsername("");
      setAmazonClientId("");
      setAmazonClientSecret("");
      setAmazonMarketplaceIds("");
      setAmazonRefreshToken("");
      submitHandler(data);
    }
    return false;
  };

  useEffect(() => {
    if (account.id) {
      setName(account.name);
      setUsername(account.username);
      setAmazonClientId(account.amazonClientId);
      setAmazonMarketplaceIds(account.amazonMarketplaceIds);
    }
  }, [account]);

  return (
    <form onSubmit={handleSave}>
      <div className="flex-grow">
        {/* Panel body */}
        <div className="p-6 space-y-6">
          {/* Business Profile */}
          <section>
            <h2 className="text-xl text-gray-800 font-bold mb-1">
              {account.id ? "Edit Account" : "Add Account"}
            </h2>
            <div className="text-sm">
              {account.id
                ? "Change the configuration for " +
                  (userStore.user.accountId ? "your " : "an existing ") +
                  "account"
                : "Add a new Shopify or Amazon account begin syncing data"}
            </div>
            {error.length > 0 && (
              <div className="mt-5">
                <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                  <span className="text-sm">{error}</span>
                </div>
              </div>
            )}
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
              <div className="sm:w-1/2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  id="name"
                  className="form-input w-full"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="sm:w-1/2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="secret"
                >
                  Shopify Username
                </label>
                <input
                  id="secret"
                  className="form-input w-full"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  disabled={account.id != null}
                />
              </div>
            </div>
          </section>

          <section>
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-3">
              <div className="w-full">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="secret"
                >
                  Shopify Password
                </label>
                <input
                  id="secret"
                  className="form-input w-full"
                  placeholder="**************************"
                  type="text"
                  value={secret}
                  onChange={(e) => setSecret(e.target.value)}
                />
              </div>
            </div>
          </section>
          <section>
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
              <div className="sm:w-1/2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="amazon_client_id"
                >
                  Amazon Client ID
                </label>
                <input
                  id="amazon_client_id"
                  className="form-input w-full"
                  type="text"
                  value={amazonClientId}
                  onChange={(e) => setAmazonClientId(e.target.value)}
                />
              </div>
              <div className="sm:w-1/2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="amazon_secret"
                >
                  Amazon Secret
                </label>
                <input
                  id="amazon_secret"
                  className="form-input w-full"
                  placeholder="**************************"
                  type="text"
                  value={amazonClientSecret}
                  onChange={(e) => setAmazonClientSecret(e.target.value)}
                />
              </div>
            </div>
          </section>
          <section>
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-3">
              <div className="w-full">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="token"
                >
                  Amazon Token
                </label>
                <input
                  id="token"
                  className="form-input w-full"
                  placeholder="***************************************"
                  type="text"
                  value={amazonRefreshToken}
                  onChange={(e) => setAmazonRefreshToken(e.target.value)}
                />
              </div>
            </div>
          </section>
          <section>
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-3">
              <div className="w-full">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="marketplace_ids"
                >
                  Amazon Market Place IDs (comma separated)
                </label>
                <input
                  id="marketplace_ids"
                  className="form-input w-full"
                  type="text"
                  value={amazonMarketplaceIds}
                  onChange={(e) => setAmazonMarketplaceIds(e.target.value)}
                />
              </div>
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-gray-200">
            <div className="flex self-end">
              <button
                type="button"
                className="btn border-gray-200 hover:border-gray-300 text-gray-600"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn bg-green hover:bg-green-dark text-white ml-3"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </footer>
      </div>
    </form>
  );
}

export default AccountForm;
