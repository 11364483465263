import { api } from "../initializers/axios";

const create = async ({
  accountId,
  startDate,
  endDate,
  exportType,
  systemFilter,
  locationFilter,
}) => {
  const { data } = await api.post("/exports", {
    accountId,
    startDate,
    endDate,
    exportType,
    systemFilter,
    locationFilter,
  });
  return { data };
};

const remove = async (ids) => {
  const { data } = await api.post("/exports/delete", { ids });
  return { data };
};

const search = async (filters = {}, options = {}) => {
  const optionsStr = Object.keys(options).reduce((str, f) => {
    str = `${str}&${f}=${options[f]}`;
    return str;
  }, "?");

  const filterStr = Object.keys(filters).reduce((str, f) => {
    str = `${str}&${f}=${filters[f]}`;
    return str;
  }, "");
  const x = api.get(`/exports${optionsStr}${filterStr}`);
  const { data, meta } = await x;
  return { data, meta };
};

const update = async (id, props) => {
  const { data } = await api.put(`/exports/${id}`, props);
  return { data };
};

const download = async (id) => {
  const { data } = await api.get(`/exports/${id}/download`);
  return data;
};

const methods = {
  create,
  download,
  remove,
  search,
  update,
};

export default methods;
