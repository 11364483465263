import meServices from "../services/me.services";
import { getAxiosOrDefaultErrorMessage } from "../initializers/axios";

export function createUserStore() {
  return {
    user: { email: "" },
    async update(props) {
      try {
        const { data } = await meServices.update(props);
        this.user = data;
        return { data };
      } catch (err) {
        const error = getAxiosOrDefaultErrorMessage(err);
        return { error };
      }
    },
    setUser(user) {
      this.user = { ...user };
    },
    hasPermission(validFor) {
      const intersect = (a, b) => {
        var setB = new Set(b);
        return [...new Set(a)].filter((x) => setB.has(x));
      };
      if (!this.user || !this.user.permissions) return false;
      return (
        intersect(validFor, Object.values(this.user.permissions)).length > 0
      );
    },
  };
}
