import moment from "moment";
import pickBy from "lodash/pickBy";
import qs from "query-string";

// Creates a queryString of all params
const buildQueryString = (params) =>
  Object.keys(params).reduce((acc, key) => {
    if (![null, undefined, ""].includes(params[key])) {
      if (Array.isArray(params[key])) {
        return params[key].length
          ? `${acc}${params[key].reduce(
              (str, val) => `${str}${key}[]=${(val && val.id) || val}&`,
              ""
            )}`
          : acc;
      }
      return `${acc}${key}=${params[key]}&`;
    }
    return acc;
  }, "?");

const getQueryParams = () => {
  const parsed = pickBy(qs.parse(window.location.search));
  Object.keys(parsed).forEach((key) => {
    if (Array.isArray(parsed[key]) && parsed[key][0] && parsed[key][0]) {
      parsed[key] = parsed[key].map((x) => {
        return { id: x };
      });
    } else if (key.includes("[]")) {
      parsed[key] = [{ id: parsed[key] }];
    }
  });
  return parsed;
};

const setQueryParams = (params = {}) => {
  let pickedParams = pickBy(params);
  Object.keys(pickedParams).forEach((key) => {
    if (typeof pickedParams[key] === "function") delete pickedParams[key];
  });

  // Format moment dates
  Object.keys(pickedParams).forEach((key) => {
    if (pickedParams[key] instanceof moment) {
      pickedParams[key] = pickedParams[key].format("YYYY-MM-DD");
    }
  });
  const queryString = buildQueryString(pickedParams);
  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}${queryString}`
  );
};

export { buildQueryString, getQueryParams, setQueryParams };
