import React from "react";

function PaginationClassic(props) {
  const skip = parseInt(props.skip);
  const limit = parseInt(props.limit);
  const total = parseInt(props.total);
  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <nav
        className="mb-4 sm:order-1"
        role="navigation"
        aria-label="Navigation"
      >
        <ul className="flex justify-center">
          <li className="ml-3 first:ml-0">
            <button
              className={`btn bg-white border-gray-200  ${
                skip === 0
                  ? "text-gray-300 cursor-not-allowed"
                  : "text-green hover:border-gray-300"
              }`}
              href="#0"
              disabled={skip === 0}
              onClick={props.previousClick}
            >
              &lt;- Previous
            </button>
          </li>
          <li className="ml-3 first:ml-0">
            <button
              className={`btn bg-white border-gray-200 ${
                parseInt(skip + limit) >= parseInt(total)
                  ? "text-gray-300 cursor-not-allowed"
                  : "text-green hover:border-gray-300"
              }`}
              href="#0"
              onClick={props.nextClick}
              disabled={parseInt(skip + limit) >= parseInt(total)}
            >
              Next -&gt;
            </button>
          </li>
        </ul>
      </nav>
      <div className="text-sm text-gray-500 text-center sm:text-left">
        Showing{" "}
        <span className="font-medium text-gray-600">
          {Math.min(total, skip + 1)}
        </span>{" "}
        to{" "}
        <span className="font-medium text-gray-600">
          {Math.min(total, parseInt(skip + limit))}
        </span>{" "}
        of <span className="font-medium text-gray-600">{total}</span> results
      </div>
    </div>
  );
}

export default PaginationClassic;
