import React, { useEffect, useState } from "react";
import { Observer } from "mobx-react-lite";
import { useToasts } from "react-toast-notifications";
import authServices from "../../services/auth.services";
import { useUserStore } from "../../state/UserContext";

function UserProfile() {
  const userStore = useUserStore();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const { addToast } = useToasts();

  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      const resp = await authServices.forgotPassword(email);
      if (resp === "OK") {
        setMessage("A password reset email has been sent");
        setError("");
      }
    } catch (err) {
      setMessage("");
      setError(err);
    }
  };

  const resetForm = () => {
    setEmail(userStore.user.email);
    setMessage("");
    setError("");
  };

  const updateMe = async (e) => {
    e.preventDefault();
    const { error } = await userStore.update({ email });
    if (error && error.length > 0) {
      setError(error);
    } else {
      addToast("Account updated!", {
        appearance: "success",
        autoDismiss: true,
      });
    }
    return false;
  };

  useEffect(() => {
    setEmail(userStore.user.email);
  }, [userStore, userStore.user]);

  return (
    <Observer>
      {() => (
        <form onSubmit={updateMe}>
          <div className="flex-grow">
            {/* Panel body */}
            <div className="p-6 space-y-6">
              {error.length > 0 && (
                <div className="mt-5">
                  <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                    <span className="text-sm">{error}</span>
                  </div>
                </div>
              )}
              {message.length > 0 && (
                <div className="mt-5">
                  <div className="bg-green-100 text-green-600 px-3 py-2 rounded">
                    <span className="text-sm">{message}</span>
                  </div>
                </div>
              )}
              {/* Email */}
              <section>
                <h2 className="text-xl text-gray-800 font-bold mb-1">Email</h2>
                <div className="flex flex-wrap mt-5">
                  <div className="mr-2">
                    <label className="sr-only" htmlFor="email">
                      Email
                    </label>
                    <input
                      id="email"
                      className="form-input"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </section>
              {/* Password */}
              <section>
                <h2 className="text-xl text-gray-800 font-bold mb-1">
                  Password
                </h2>

                <div className="mt-5">
                  <button
                    className="btn border-gray-200 shadow-sm text-green"
                    onClick={resetPassword}
                  >
                    Reset Password
                  </button>
                </div>
              </section>
            </div>
            {/* Panel footer */}
            <footer>
              <div className="flex flex-col px-6 py-5 border-t border-gray-200">
                <div className="flex self-end">
                  <button
                    type="button"
                    className="btn border-gray-200 hover:border-gray-300 text-gray-600"
                    onClick={resetForm}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn bg-green hover:bg-green-dark text-white ml-3"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </footer>
          </div>
        </form>
      )}
    </Observer>
  );
}

export default UserProfile;
