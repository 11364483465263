import React, { useState } from "react";
import { Observer } from "mobx-react-lite";

import { useUserStore } from "../state/UserContext";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardFilters from "../partials/dashboard/DashboardFilters";
import OverviewTable from "../partials/dashboard/OverviewTable";
import XtoDateTable from "../partials/dashboard/XtoDateTable";

import { useStore } from "../state/DashboardContext";

function Overview() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const store = useStore();
  const userStore = useUserStore();
  store.setQueryParams();

  return (
    <Observer>
      {() => (
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Dashboard actions */}

                <DashboardFilters startDate endDate sliceBy accounts />

                {/* Cards */}
                <OverviewTable
                  account={
                    store.accountId ||
                    (userStore.user && userStore.user.accountId)
                  }
                  sliceBy={store.sliceBy}
                  startDate={store.startDate}
                  endDate={store.endDate}
                />
                <XtoDateTable
                  account={
                    store.accountId ||
                    (userStore.user && userStore.user.accountId)
                  }
                />
              </div>
            </main>
          </div>
        </div>
      )}
    </Observer>
  );
}

export default Overview;
