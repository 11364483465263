import React from "react";
import moment from "moment";
import { useToasts } from "react-toast-notifications";

import EditMenu from "../EditMenu";

import accountServices from "../../services/account.services";

function AccountsTableItem({
  item,
  menuCallback,
  handleClick,
  isChecked,
  setActive,
}) {
  const { addToast } = useToasts();

  const selectItemForEdit = (item) => {
    menuCallback("edit", item);
  };

  const selectItemForSync = async (item) => {
    await accountServices.sync(item.id);
    addToast("A data sync has been initiated!", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const selectItemForReload = async (item) => {
    await accountServices.sync(item.id, null, "reload");
    addToast("A data reset has been initiated!", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center">
          <label className="inline-flex">
            <span className="sr-only">Select</span>
            <input
              id={`check-account-${item.id}`}
              className="form-checkbox"
              type="checkbox"
              onChange={(e) => handleClick(e, item.id)}
              checked={isChecked}
            />
          </label>
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{item.name}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{item.username}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{item.amazonClientId}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {item.lastSuccessfulSyncAt
            ? moment(item.lastSuccessfulSyncAt).format("MM/DD/yyyy @ hh:mm A")
            : "-"}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {item.amazonLastSuccessfulSyncAt
            ? moment(item.amazonLastSuccessfulSyncAt).format(
                "MM/DD/yyyy @ hh:mm A"
              )
            : "-"}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          <div
            className="form-switch focus-within:shadow-outline"
            style={{ margin: "0 auto" }}
          >
            <input
              type="checkbox"
              id={`sync-account-${item.id}`}
              className="sr-only"
              checked={item.active}
              onChange={() => setActive(item.id, !item.active)}
            />
            <label className="bg-gray-400" htmlFor={`sync-account-${item.id}`}>
              <span className="bg-white shadow-sm" aria-hidden="true"></span>
              <span className="sr-only">Enable sync</span>
            </label>
          </div>
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <EditMenu
          className="relative inline-flex flex-shrink-0"
          key={`account_${item.id}`}
        >
          <li>
            <button
              className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
              onClick={() => selectItemForEdit({ ...item })}
            >
              EDIT
            </button>
            <button
              className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
              onClick={() => selectItemForSync({ ...item })}
            >
              SYNC NOW
            </button>
            <button
              className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
              onClick={() => selectItemForReload({ ...item })}
            >
              RESET DATA
            </button>
          </li>
        </EditMenu>
      </td>
    </tr>
  );
}

export default AccountsTableItem;
