import React from "react";
import { createStore } from "./listStore";
import { useLocalObservable } from "mobx-react-lite";
import exportServices from "../services/export.services";

const ExportsContext = React.createContext(null);

export const ExportsProvider = ({ children }) => {
  const store = useLocalObservable(createStore(exportServices));

  return (
    <ExportsContext.Provider value={store}>{children}</ExportsContext.Provider>
  );
};

export const useStore = () => React.useContext(ExportsContext);
