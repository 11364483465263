import { classNames } from "../utils/Utils";
const Table = ({ columns = [], data = [] }) => {
  const doNothing = () => {};

  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        {/* head */}
        <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
          <tr>
            {columns.map((c, i) => {
              return (
                <th
                  key={i}
                  onClick={c.onClick || doNothing}
                  className={classNames(
                    c.onClick ? "cursor-pointer" : "",
                    "px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-left"
                  )}
                >
                  <span
                    className={classNames(c.selected ? "italic" : "semi-bold")}
                  >
                    {c.title}
                  </span>
                  {c.sortDirection && (
                    <span className="ml-1">
                      {c.selected
                        ? c.sortDirection === "ASC"
                          ? "↑"
                          : "↓"
                        : "↑ ↓"}
                    </span>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-sm divide-y divide-gray-200">
          {data.map((r, i) => {
            return (
              <tr key={i}>
                {columns.map((c, i) => {
                  return (
                    <td
                      className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                      key={i}
                    >
                      {c.render ? c.render(r) : ""}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
