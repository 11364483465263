import React, { useState, useEffect } from "react";
import { focusHandling } from "cruip-js-toolkit";
import Account from "./AccountsTableItem";

import { useStore } from "../../state/AccountsContext";

function AccountsTable({
  list,
  menuCallback,
  selectedItems,
  onSelectedChange,
}) {
  const [selectAll, setSelectAll] = useState(false);

  const store = useStore();

  useEffect(() => {
    focusHandling("outline");
  }, [list]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    onSelectedChange(list.map((li) => li.id));
    if (selectAll) {
      onSelectedChange([]);
    }
  };

  const handleClick = (e, id) => {
    const { checked } = e.target;
    setSelectAll(false);
    if (!checked) {
      onSelectedChange(selectedItems.filter((item) => item !== id));
    } else {
      onSelectedChange([...selectedItems, id]);
    }
  };

  const setActive = async (id, active) => {
    await store.update(id, { active });
  };

  return (
    <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800">
          All Accounts{" "}
          <span className="text-gray-400 font-medium">{store.total}</span>
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="flex items-center">
                    <label className="inline-flex">
                      <span className="sr-only">Select all</span>
                      <input
                        className="form-checkbox"
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </label>
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">
                    Shopify Username
                  </div>
                </th>
                <th className="px-2 first:pl-5Username last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">
                    Amazon Client ID
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold">Shopify Last Sync</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold">Amazon Last Sync</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold">Active</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <span className="sr-only">Menu</span>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {list.map((a) => {
                return (
                  <Account
                    key={a.id}
                    id={a.id}
                    handleClick={handleClick}
                    setActive={setActive}
                    isChecked={selectedItems.includes(a.id)}
                    item={a}
                    menuCallback={menuCallback}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AccountsTable;
