import React, { useState, useEffect } from "react";
import CohortOrderCountsTableItem from "./CohortOrderCountsTableItem ";
import Loader from "../components/Loader";
import calcServices from "../../services/calc.services";
import { download } from "../../utils/XLXS";

function CohortOrderCountsTable({ account, startDate, endDate, sliceBy }) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCalcData = async (account, startDate, endDate, sliceBy) => {
      if (!account) return;
      setLoading(true);
      const { data } = await calcServices.cohortOrderCounts(
        account,
        sliceBy,
        startDate,
        endDate
      );
      setTableData(data);
      setLoading(false);
    };
    fetchCalcData(account, startDate, endDate, sliceBy);
  }, [account, sliceBy, startDate, endDate]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200 mt-4 overflow-x-auto">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center relative">
        <h2 className="font-semibold text-gray-800">
          Returning Customer Count
        </h2>
        <button
          type="button"
          className="absolute btn bg-green hover:bg-green-dark text-white ml-3"
          style={{ right: "1rem", top: ".5rem" }}
          onClick={() => download(tableData, "cohort-order-counts")}
        >
          Download
        </button>
      </header>
      {tableData.length > 0 && !loading && (
        <table className="table-auto w-full">
          {/* Table header */}
          <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
            <tr>
              {tableData[0] &&
                Object.keys(tableData[0]).map((k, i) => {
                  return (
                    <th className="px-2 py-3 whitespace-nowrap" key={i}>
                      <div
                        className={
                          (k === "cohort" ? "text-left" : "text-center") +
                          " font-semibold"
                        }
                      >
                        {k.toUpperCase()}
                      </div>
                    </th>
                  );
                })}
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm divide-y divide-gray-200">
            {tableData.map((x, i) => {
              return <CohortOrderCountsTableItem key={i} item={x} />;
            })}
          </tbody>
        </table>
      )}
      <Loader visible={account && loading} />
    </div>
  );
}

export default CohortOrderCountsTable;
