import React, { useEffect, useMemo, useState } from "react";
import { Observer } from "mobx-react-lite";
import { useToasts } from "react-toast-notifications";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DeleteButton from "../partials/actions/DeleteButton";
import FilterButton from "../partials/actions/FilterButton";
import AccountForm from "../partials/accounts/AccountForm";
import AccountsTable from "../partials/accounts/AccountsTable";
import Modal from "../partials/Modal";
import PaginationClassic from "../partials/PaginationClassic";

import { useStore } from "../state/AccountsContext";

function Accounts() {
  const PAGE_SIZE = 20;

  const [deleteError, setDeleteError] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});

  const store = useStore();
  const { addToast } = useToasts();

  const deleteAccounts = async () => {
    const { error } = await store.remove();
    if (!error) {
      setDeleteModalOpen(false);
      setDeleteError("");
      // pop a toaster
      addToast("Account(s) deleted!", {
        appearance: "success",
        autoDismiss: true,
      });
      store.fetch();
    } else if (error) {
      setDeleteError(error);
    }
  };

  const handleFilterApply = () => {
    store.fetch();
  };

  const handleFilterReset = () => {
    store.setFilters(defaultFilters);
  };

  const handleFilterChange = (key) => {
    store.setFilters({ ...store.filters, [key]: !store.filters[key] });
  };

  const handleMenuCallback = (action, payload) => {
    if (action === "edit") {
      setSelectedAccount(payload);
      setModalOpen(true);
    }
  };

  const handleSelectedItems = (selectedItems) => {
    store.setSelectedItems(selectedItems);
  };

  const handlePrevClick = () => {
    store.setSkip(store.skip - PAGE_SIZE);
  };

  const handleNextClick = () => {
    store.setSkip(store.skip + PAGE_SIZE);
  };

  const handleSubmit = () => {
    store.fetch();
    setModalOpen(false);
    if (selectedAccount.id) {
      addToast("Account updated!", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast("Account created!", {
        appearance: "success",
        autoDismiss: true,
      });
    }
    setSelectedAccount({});
  };

  const defaultFilters = useMemo(() => {
    return {
      active: false,
    };
  }, []);

  useEffect(() => {
    store.setFilters(defaultFilters);
    store.setLimit(PAGE_SIZE);
    store.setSortProperty("name");
    store.fetch();
  }, [defaultFilters, store]);

  return (
    <Observer>
      {() => (
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                      Accounts
                    </h1>
                  </div>

                  {/* Right: Actions */}
                  <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    {/* Delete button */}
                    <Modal
                      isOpen={deleteModalOpen}
                      buttonHandler={() => setDeleteModalOpen(true)}
                      cancelHandler={() => setDeleteModalOpen(false)}
                      button={
                        <DeleteButton selectedItems={store.selectedItems} />
                      }
                    >
                      <div className="flex-grow">
                        {/* Panel body */}
                        <div className="p-6 space-y-6">
                          {deleteError.length > 0 && (
                            <div className="mt-5">
                              <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                                <span className="text-sm">{deleteError}</span>
                              </div>
                            </div>
                          )}
                          <section>
                            <h2 className="text-xl text-gray-800 font-bold mb-1">
                              Delete {store.selectedItems.length} Account(s)
                            </h2>
                            <div className="text-sm">
                              This can not be undone
                            </div>
                          </section>
                        </div>
                        {/* Panel footer */}
                        <footer>
                          <div className="flex flex-col px-6 py-5 border-t border-gray-200">
                            <div className="flex self-end">
                              <button
                                type="button"
                                className="btn border-gray-200 hover:border-gray-300 text-gray-600"
                                onClick={() => setDeleteModalOpen(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn bg-green hover:bg-green-dark text-white ml-3"
                                onClick={deleteAccounts}
                              >
                                Yes, Delete
                              </button>
                            </div>
                          </div>
                        </footer>
                      </div>
                    </Modal>
                    {/* Filter button */}
                    <FilterButton
                      filters={store.filters}
                      onChange={handleFilterChange}
                      applyFilters={handleFilterApply}
                      resetFilters={handleFilterReset}
                    />
                    {/* Add Modal Button */}
                    <Modal
                      isOpen={modalOpen}
                      buttonHandler={() => setModalOpen(true)}
                      cancelHandler={() => {
                        setModalOpen(false);
                      }}
                      button={
                        <button className="btn bg-green hover:bg-green-dark text-white">
                          <svg
                            className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                          </svg>
                          <span className="hidden xs:block ml-2">
                            Add Account
                          </span>
                        </button>
                      }
                    >
                      <AccountForm
                        account={selectedAccount}
                        submitHandler={handleSubmit}
                        cancelHandler={() => {
                          setModalOpen(false);
                        }}
                      />
                    </Modal>
                  </div>
                </div>

                {/* Table */}
                <AccountsTable
                  selectedItems={store.selectedItems}
                  onSelectedChange={handleSelectedItems}
                  menuCallback={handleMenuCallback}
                  list={store.items}
                />

                {/* Pagination */}
                <div className="mt-8">
                  <PaginationClassic
                    limit={store.total > PAGE_SIZE ? PAGE_SIZE : store.total}
                    skip={store.skip}
                    total={store.total}
                    previousClick={handlePrevClick}
                    nextClick={handleNextClick}
                  />
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </Observer>
  );
}

export default Accounts;
