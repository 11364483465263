import { api } from "../initializers/axios";

const aov = async (accountId, sliceBy, start, end) => {
  const { data } = await api.post("/calcs/aov", {
    accountId,
    sliceBy,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });
  return { data };
};

const aovVs = async (accountId, sliceBy, start, end) => {
  const { data } = await api.post("/calcs/aov-vs", {
    accountId,
    sliceBy,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });
  return { data };
};

const cac = async (accountId, start, end, type) => {
  const { data } = await api.post("/calcs/cac", {
    accountId,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
    type: type || "paid",
  });
  return { data };
};

const cohortOrderCounts = async (accountId, sliceBy, start, end) => {
  const { data } = await api.post("/calcs/cohort-order-counts", {
    accountId,
    sliceBy,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });
  return { data };
};

const cohortShipRetention = async (accountId, sliceBy, start, end) => {
  const { data } = await api.post("/calcs/cohort-shipping-retention", {
    accountId,
    sliceBy,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });

  return { data };
};

const cohortRevenue = async (accountId, sliceBy, start, end) => {
  const { data } = await api.post("/calcs/cohort-revenue", {
    accountId,
    sliceBy,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });
  return { data };
};

const cumulativeNetRev = async (accountId, sliceBy, start, end) => {
  const { data } = await api.post("/calcs/cumulative-net-rev", {
    accountId,
    sliceBy,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });
  return { data };
};

const customersByInterval = async (accountId, sliceBy, start, end) => {
  const { data } = await api.post("/calcs/customers-by-cohort", {
    accountId,
    sliceBy,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });
  return { data };
};

const grossProfitPerCustomer = async (
  accountId,
  start,
  end,
  contributionMargin
) => {
  const { data } = await api.post("/calcs/gross-profit-per-customer", {
    accountId,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
    contributionMargin,
  });
  return { data };
};

const ltvToCac = async (
  accountId,
  start,
  end,
  type,
  contributionMargin,
  cac
) => {
  const { data } = await api.post("/calcs/ltv-to-cac", {
    accountId,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
    type,
    contributionMargin,
    cac,
  });
  return { data };
};

const lifetimeNetRev = async (accountId, sliceBy, start, end) => {
  const { data } = await api.post("/calcs/lifetime-net-rev", {
    accountId,
    sliceBy,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });
  return { data };
};

const newVsReturningRev = async (accountId, start, end) => {
  const { data } = await api.post("/calcs/new-vs-return-rev", {
    accountId,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });
  return { data };
};

const newVsReturningRevInterval = async (accountId, sliceBy, start, end) => {
  const { data } = await api.post("/calcs/new-vs-return-rev-cohort", {
    accountId,
    sliceBy,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });
  return { data };
};

const orderFrequency = async (accountId, start, end) => {
  const { data } = await api.post("/calcs/order-frequency", {
    accountId,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });
  return { data };
};

const overview = async (accountId, sliceBy, start, end) => {
  const { data } = await api.post("/calcs/overview", {
    accountId,
    sliceBy,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });
  return { data };
};

const retention = async (accountId, sliceBy) => {
  const { data } = await api.post("/calcs/retention", {
    accountId,
    sliceBy,
  });
  return { data };
};

const revTable = async (accountId, sliceBy, start, end) => {
  const { data } = await api.post("/calcs/revenue-table", {
    accountId,
    sliceBy,
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  });
  return { data };
};

const xToDate = async (accountId) => {
  const { data } = await api.post("/calcs/x-to-date", {
    accountId,
  });
  return { data };
};

const methods = {
  aov,
  aovVs,
  cac,
  cohortOrderCounts,
  cohortRevenue,
  cohortShipRetention,
  cumulativeNetRev,
  customersByInterval,
  grossProfitPerCustomer,
  ltvToCac,
  lifetimeNetRev,
  newVsReturningRev,
  newVsReturningRevInterval,
  orderFrequency,
  overview,
  retention,
  revTable,
  xToDate,
};

export default methods;
