import React from "react";
import { createStore } from "./listStore";
import { useLocalObservable } from "mobx-react-lite";
import userServices from "../services/user.services";

const UsersContext = React.createContext(null);

export const UsersProvider = ({ children }) => {
  const store = useLocalObservable(
    createStore(userServices, {
      accountId: null,
      id: null,
      email: "",
      password: "",
    })
  );

  return (
    <UsersContext.Provider value={store}>{children}</UsersContext.Provider>
  );
};

export const useStore = () => React.useContext(UsersContext);
