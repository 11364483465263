import Cookies from "../utils/Cookies";
import { api } from "../initializers/axios";

const forgotPassword = async (email) => {
  try {
    const { data } = await api.post("/auth/reset-password", { email });
    return data;
  } catch (err) {
    return false;
  }
};

const changePassword = async (token, password) => {
  const resp = await api.post("/auth/change-password", { token, password });
  return resp;
};

const login = async (props = {}) => {
  try {
    Cookies.delete("ACCESS_TOKEN");
    const url =
      props.accountType === "admin" ? "/auth/admin/login" : "/auth/login";
    const { data } = await api.post(url, {
      username: props.email,
      password: props.password,
    });
    if (data) {
      Cookies.set("REFRESH_TOKEN", data, 10);
      const redirectUrl = Cookies.get("REDIRECT_URL");
      Cookies.delete("REDIRECT_URL");
      return redirectUrl || "/";
    }
  } catch (err) {
    return null;
  }
};

const logout = () => {
  // TODO: call the logoout endpoint with the refresh token
  Cookies.delete("ACCESS_TOKEN");
  Cookies.delete("REFRESH_TOKEN");
  window.location = "/login";
};

const methods = {
  changePassword,
  forgotPassword,
  login,
  logout,
};

export default methods;
