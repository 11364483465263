import React, { useEffect, useState } from "react";
import { Observer } from "mobx-react-lite";
import { useToasts } from "react-toast-notifications";
import cmServices from "../../services/contribution-margin.services";
import { useUserStore } from "../../state/UserContext";

import Form from "../contribution-margins/CMForm";

function ContributionMargin() {
  const userStore = useUserStore();
  const [cm, setCM] = useState(0);

  const { addToast } = useToasts();

  const cancelHandler = () => {
    setCM({ ...cm });
  };

  const submitHandler = (data) => {
    setCM(data);
    addToast("Contribution Margin updated!", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  useEffect(() => {
    const fetch = async () => {
      if (!userStore.user.accountId) return;
      try {
        const { data } = await cmServices.get(userStore.user.accountId);
        setCM(data);
      } catch (err) {
        setCM({});
      }
    };
    fetch();
  }, [userStore, userStore.user]);

  return (
    <Observer>
      {() => (
        <Form
          item={cm}
          cancelHandler={cancelHandler}
          submitHandler={submitHandler}
        />
      )}
    </Observer>
  );
}

export default ContributionMargin;
