import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Cookies from "../utils/Cookies";
import LogoImage from "../images/logo.png";
import { getQueryParams } from "../utils/QueryParams";
import authServices from "../services/auth.services";

import AuthImage from "../images/flatirons.jpeg";
// import AuthDecoration from "../images/auth-decoration.png";

function Signin() {
  const handleSignIn = async (e) => {
    e.preventDefault();
    const result = await authServices.login({ email, password, accountType });
    if (result) {
      window.location = result;
    } else {
      setError("email or password is incorrect");
    }
    return false;
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [accountType, setAccountType] = useState("user");

  useEffect(() => {
    Cookies.delete("ACCESS_TOKEN");
    Cookies.delete("REFRESH_TOKEN");
    const queryParams = getQueryParams();
    if (queryParams.type && queryParams.type === "admin") {
      setAccountType("admin");
    }
  }, [setAccountType]);

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              <img src={LogoImage} alt="Logo" />
              {error.length > 0 && (
                <div className="mt-5">
                  <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                    <span className="text-sm">{error}</span>
                  </div>
                </div>
              )}
              {/* Form */}
              <form onSubmit={handleSignIn}>
                <div className="space-y-4 mt-10">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      id="email"
                      className="mt-1 block w-full rounded-md bg-gray-100 border-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      className="mt-1 block w-full rounded-md bg-gray-100 border-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                      type="password"
                      autoComplete="on"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <Link
                      className="text-sm underline hover:no-underline"
                      to="/forgot-password"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <button
                    className="btn bg-green hover:bg-green-dark text-white ml-3"
                    type="submit"
                  >
                    Sign In
                  </button>
                </div>
              </form>
              <div className="mt-10 font-medium">
                For more information or to subscribe please email{" "}
                <a href="mailto:info@solidratio.com" className="underline">
                  info@solidratio.com
                </a>
              </div>
              {/* Footer */}
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
        </div>
      </div>
    </main>
  );
}

export default Signin;
