import React, { useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Observer } from "mobx-react-lite";

import LogoImage from "../images/logo.png";
import { useUserStore } from "../state/UserContext";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;
  const page = pathname.split("/")[1];

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const userStore = useUserStore();

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <Observer>
      {() => (
        <div className="lg:w-64">
          {/* Sidebar backdrop (mobile only) */}
          <div
            className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
              sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
            aria-hidden="true"
          ></div>

          {/* Sidebar */}
          <div
            id="sidebar"
            ref={sidebar}
            className={`absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 flex-shrink-0 bg-gray-800 p-4 transition-transform duration-200 ease-in-out ${
              sidebarOpen ? "translate-x-0" : "-translate-x-64"
            }`}
          >
            {/* Sidebar header */}
            <div className="flex justify-between mb-10 pr-3 sm:px-2">
              {/* Close button */}
              <button
                ref={trigger}
                className="lg:hidden text-gray-500 hover:text-gray-400"
                onClick={() => setSidebarOpen(!sidebarOpen)}
                aria-controls="sidebar"
                aria-expanded={sidebarOpen}
              >
                <span className="sr-only">Close sidebar</span>
                <svg
                  className="w-6 h-6 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
                </svg>
              </button>
              {/* Logo */}
              <NavLink exact to="/" className="block">
                <img src={LogoImage} width="120" alt="Logo" />
              </NavLink>
            </div>

            {/* Links */}
            <div>
              <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">
                Pages
              </h3>
              <ul className="mt-3">
                {/* Overview */}
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    page === "overview" && "bg-gray-900"
                  }`}
                >
                  <NavLink
                    exact
                    to="/"
                    className={`block text-gray-200 hover:text-white transition duration-150 ${
                      page === "overview" && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex flex-grow">
                      <svg
                        className="flex-shrink-0 h-6 w-6 mr-3"
                        viewBox="0 0 24 24"
                      >
                        <path
                          className={`fill-current text-gray-400 ${
                            page === "overview" && "text-green"
                          }`}
                          d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                        />
                        <path
                          className={`fill-current text-gray-600 ${
                            page === "overview" && "text-green-dark"
                          }`}
                          d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                        />
                        <path
                          className={`fill-current text-gray-400 ${
                            page === "overview" && "text-green-light"
                          }`}
                          d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                        />
                      </svg>
                      <span className="text-sm font-medium">Overview</span>
                    </div>
                  </NavLink>
                </li>
                {/* Customer Acquisitions */}
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    page === "acquisitions" && "bg-gray-900"
                  }`}
                >
                  <NavLink
                    exact
                    to="/acquisitions"
                    className={`block text-gray-200 hover:text-white transition duration-150 ${
                      page === "acquisitions" && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex flex-grow">
                      <svg
                        className="flex-shrink-0 h-6 w-6 mr-3"
                        viewBox="0 0 24 24"
                      >
                        <path
                          className={`fill-current text-gray-400 ${
                            page === "acquisitions" && "text-green"
                          }`}
                          d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                        />
                        <path
                          className={`fill-current text-gray-600 ${
                            page === "acquisitions" && "text-green-dark"
                          }`}
                          d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                        />
                        <path
                          className={`fill-current text-gray-400 ${
                            page === "acquisitions" && "text-green-light"
                          }`}
                          d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                        />
                      </svg>
                      <span className="text-sm font-medium">Acquisitions</span>
                    </div>
                  </NavLink>
                </li>
                {/* Retention */}
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    page === "retention" && "bg-gray-900"
                  }`}
                >
                  <NavLink
                    exact
                    to="/retention"
                    className={`block text-gray-200 hover:text-white transition duration-150 ${
                      page === "retention" && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex flex-grow">
                      <svg
                        className="flex-shrink-0 h-6 w-6 mr-3"
                        viewBox="0 0 24 24"
                      >
                        <path
                          className={`fill-current text-gray-400 ${
                            page === "retention" && "text-green"
                          }`}
                          d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                        />
                        <path
                          className={`fill-current text-gray-600 ${
                            page === "retention" && "text-green-dark"
                          }`}
                          d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                        />
                        <path
                          className={`fill-current text-gray-400 ${
                            page === "retention" && "text-green-light"
                          }`}
                          d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                        />
                      </svg>
                      <span className="text-sm font-medium">Retention</span>
                    </div>
                  </NavLink>
                </li>
                {/* LTV */}
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    page === "ltv" && "bg-gray-900"
                  }`}
                >
                  <NavLink
                    exact
                    to="/ltv"
                    className={`block text-gray-200 hover:text-white transition duration-150 ${
                      page === "ltv" && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex flex-grow">
                      <svg
                        className="flex-shrink-0 h-6 w-6 mr-3"
                        viewBox="0 0 24 24"
                      >
                        <path
                          className={`fill-current text-gray-400 ${
                            page === "ltv" && "text-green"
                          }`}
                          d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                        />
                        <path
                          className={`fill-current text-gray-600 ${
                            page === "ltv" && "text-green-dark"
                          }`}
                          d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                        />
                        <path
                          className={`fill-current text-gray-400 ${
                            page === "ltv" && "text-green-light"
                          }`}
                          d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                        />
                      </svg>
                      <span className="text-sm font-medium">LTV</span>
                    </div>
                  </NavLink>
                </li>
                {/* Forecasting */}
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    page === "forecasting" && "bg-gray-900"
                  }`}
                >
                  <NavLink
                    exact
                    to="/forecasting"
                    className={`block text-gray-200 hover:text-white transition duration-150 ${
                      page === "forecasting" && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex flex-grow">
                      <svg
                        className="flex-shrink-0 h-6 w-6 mr-3"
                        viewBox="0 0 24 24"
                      >
                        <path
                          className={`fill-current text-gray-400 ${
                            page === "forecasting" && "text-green"
                          }`}
                          d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                        />
                        <path
                          className={`fill-current text-gray-600 ${
                            page === "forecasting" && "text-green-dark"
                          }`}
                          d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                        />
                        <path
                          className={`fill-current text-gray-400 ${
                            page === "forecasting" && "text-green-light"
                          }`}
                          d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                        />
                      </svg>
                      <span className="text-sm font-medium">Forecasting</span>
                    </div>
                  </NavLink>
                </li>
                {/* Orders */}
                {userStore.user && userStore.hasPermission(["READ_ORDERS"]) && (
                  <li
                    className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                      page === "orders" && "bg-gray-900"
                    }`}
                  >
                    <NavLink
                      exact
                      to="/orders"
                      className={`block text-gray-200 hover:text-white transition duration-150 ${
                        page === "orders" && "hover:text-gray-200"
                      }`}
                    >
                      <div className="flex flex-grow">
                        <svg
                          className="flex-shrink-0 h-6 w-6 mr-3"
                          viewBox="0 0 24 24"
                        >
                          <path
                            className={`fill-current text-gray-400 ${
                              page === "orders" && "text-green-light"
                            }`}
                            d="M7 0l6 7H8v10H6V7H1z"
                          />
                          <path
                            className={`fill-current text-gray-600 ${
                              page === "orders" && "text-green"
                            }`}
                            d="M18 7v10h5l-6 7-6-7h5V7z"
                          />
                        </svg>
                        <span className="text-sm font-medium">Orders</span>
                      </div>
                    </NavLink>
                  </li>
                )}
                {/* Order Items */}
                {userStore.user && userStore.hasPermission(["READ_ORDERS"]) && (
                  <li
                    className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                      page === "order-items" && "bg-gray-900"
                    }`}
                  >
                    <NavLink
                      exact
                      to="/order-items"
                      className={`block text-gray-200 hover:text-white transition duration-150 ${
                        page === "order-items" && "hover:text-gray-200"
                      }`}
                    >
                      <div className="flex flex-grow">
                        <svg
                          className="flex-shrink-0 h-6 w-6 mr-3"
                          viewBox="0 0 24 24"
                        >
                          <path
                            className={`fill-current text-gray-400 ${
                              page === "order-items" && "text-green-light"
                            }`}
                            d="M7 0l6 7H8v10H6V7H1z"
                          />
                          <path
                            className={`fill-current text-gray-600 ${
                              page === "order-items" && "text-green"
                            }`}
                            d="M18 7v10h5l-6 7-6-7h5V7z"
                          />
                        </svg>
                        <span className="text-sm font-medium">Order Items</span>
                      </div>
                    </NavLink>
                  </li>
                )}
                {userStore.user && userStore.hasPermission(["READ_ORDERS"]) && (
                  <li
                    className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                      page === "transactions" && "bg-gray-900"
                    }`}
                  >
                    <NavLink
                      exact
                      to="/transactions"
                      className={`block text-gray-200 hover:text-white transition duration-150 ${
                        page === "transactions" && "hover:text-gray-200"
                      }`}
                    >
                      <div className="flex flex-grow">
                        <svg
                          className="flex-shrink-0 h-6 w-6 mr-3"
                          viewBox="0 0 24 24"
                        >
                          <path
                            className={`fill-current text-gray-400 ${
                              page === "transactions" && "text-green-light"
                            }`}
                            d="M7 0l6 7H8v10H6V7H1z"
                          />
                          <path
                            className={`fill-current text-gray-600 ${
                              page === "transactions" && "text-green"
                            }`}
                            d="M18 7v10h5l-6 7-6-7h5V7z"
                          />
                        </svg>
                        <span className="text-sm font-medium">
                          Transactions
                        </span>
                      </div>
                    </NavLink>
                  </li>
                )}
                {/* Admins */}
                {userStore.user && userStore.hasPermission(["READ_ADMINS"]) && (
                  <li
                    className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                      page === "admins" && "bg-gray-900"
                    }`}
                  >
                    <NavLink
                      exact
                      to="/admins"
                      className={`block text-gray-200 hover:text-white transition duration-150 ${
                        page === "admins" && "hover:text-gray-200"
                      }`}
                    >
                      <div className="flex flex-grow">
                        <svg
                          className="flex-shrink-0 h-6 w-6 mr-3"
                          viewBox="0 0 24 24"
                        >
                          <path
                            className={`fill-current text-gray-600 ${
                              page === "admins" && "text-green"
                            }`}
                            d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
                          />
                          <path
                            className={`fill-current text-gray-400 ${
                              page === "admins" && "text-green-light"
                            }`}
                            d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
                          />
                        </svg>
                        <span className="text-sm font-medium">Admins</span>
                      </div>
                    </NavLink>
                  </li>
                )}
                {/* Users */}
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    page === "users" && "bg-gray-900"
                  }`}
                >
                  <NavLink
                    exact
                    to="/users"
                    className={`block text-gray-200 hover:text-white transition duration-150 ${
                      page === "users" && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex flex-grow">
                      <svg
                        className="flex-shrink-0 h-6 w-6 mr-3"
                        viewBox="0 0 24 24"
                      >
                        <path
                          className={`fill-current text-gray-600 ${
                            page === "users" && "text-green"
                          }`}
                          d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
                        />
                        <path
                          className={`fill-current text-gray-400 ${
                            page === "users" && "text-green-light"
                          }`}
                          d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
                        />
                      </svg>
                      <span className="text-sm font-medium">Users</span>
                    </div>
                  </NavLink>
                </li>
                {/* Accounts */}
                {userStore.user &&
                  userStore.hasPermission(["READ_ACCOUNTS"]) && (
                    <li
                      className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        page === "accounts" && "bg-gray-900"
                      }`}
                    >
                      <NavLink
                        exact
                        to="/accounts"
                        className={`block text-gray-200 hover:text-white transition duration-150 ${
                          page === "accounts" && "hover:text-gray-200"
                        }`}
                      >
                        <div className="flex flex-grow">
                          <svg
                            className="flex-shrink-0 h-6 w-6 mr-3"
                            viewBox="0 0 24 24"
                          >
                            <path
                              className={`fill-current text-gray-400 ${
                                page === "accounts" && "text-green-light"
                              }`}
                              d="M7 0l6 7H8v10H6V7H1z"
                            />
                            <path
                              className={`fill-current text-gray-600 ${
                                page === "accounts" && "text-green"
                              }`}
                              d="M18 7v10h5l-6 7-6-7h5V7z"
                            />
                          </svg>
                          <span className="text-sm font-medium">Accounts</span>
                        </div>
                      </NavLink>
                    </li>
                  )}
                {/* Marketing Spend */}
                {userStore.user &&
                  userStore.hasPermission(["READ_MS", "MANAGE_MS"]) && (
                    <li
                      className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        page === "marketing-spend" && "bg-gray-900"
                      }`}
                    >
                      <NavLink
                        exact
                        to="/marketing-spend"
                        className={`block text-gray-200 hover:text-white transition duration-150 ${
                          page === "marketing-spend" && "hover:text-gray-200"
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex flex-grow">
                            <svg
                              className="flex-shrink-0 h-6 w-6 mr-3"
                              viewBox="0 0 24 24"
                            >
                              <path
                                className={`fill-current text-gray-400 ${
                                  page === "marketing-spend" &&
                                  "text-green-light"
                                }`}
                                d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                              />
                              <path
                                className={`fill-current text-gray-700 ${
                                  page === "marketing-spend" &&
                                  "text-green-dark"
                                }`}
                                d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                              />
                              <path
                                className={`fill-current text-gray-600 ${
                                  page === "marketing-spend" && "text-green"
                                }`}
                                d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                              />
                            </svg>
                            <span className="text-sm font-medium">
                              Marketing Spend
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                  )}
                {/* Contribution Margins */}
                {userStore.user &&
                  userStore.hasPermission(["READ_CM"]) &&
                  !userStore.user.accountId && (
                    <li
                      className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        page === "contribution-margins" && "bg-gray-900"
                      }`}
                    >
                      <NavLink
                        exact
                        to="/contribution-margins"
                        className={`block text-gray-200 hover:text-white transition duration-150 ${
                          page === "contribution-margins" &&
                          "hover:text-gray-200"
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex flex-grow">
                            <svg
                              className="flex-shrink-0 h-6 w-6 mr-3"
                              viewBox="0 0 24 24"
                            >
                              <path
                                className={`fill-current text-gray-400 ${
                                  page === "contribution-margins" &&
                                  "text-green-light"
                                }`}
                                d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                              />
                              <path
                                className={`fill-current text-gray-700 ${
                                  page === "contribution-margins" &&
                                  "text-green-dark"
                                }`}
                                d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                              />
                              <path
                                className={`fill-current text-gray-600 ${
                                  page === "contribution-margins" &&
                                  "text-green"
                                }`}
                                d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                              />
                            </svg>
                            <span className="text-sm font-medium">
                              Contribution Margins
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                  )}

                {/* Exports */}
                {userStore.user &&
                  userStore.hasPermission(["MANAGE_EXPORTS"]) &&
                  !userStore.user.accountId && (
                    <li
                      className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        page === "exports" && "bg-gray-900"
                      }`}
                    >
                      <NavLink
                        exact
                        to="/exports"
                        className={`block text-gray-200 hover:text-white transition duration-150 ${
                          page === "exports" && "hover:text-gray-200"
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex flex-grow">
                            <svg
                              className="flex-shrink-0 h-6 w-6 mr-3"
                              viewBox="0 0 24 24"
                            >
                              <path
                                className={`fill-current text-gray-400 ${
                                  page === "exports" && "text-green-light"
                                }`}
                                d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                              />
                              <path
                                className={`fill-current text-gray-700 ${
                                  page === "exports" && "text-green-dark"
                                }`}
                                d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                              />
                              <path
                                className={`fill-current text-gray-600 ${
                                  page === "exports" && "text-green"
                                }`}
                                d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                              />
                            </svg>
                            <span className="text-sm font-medium">Exports</span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                  )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
}

export default Sidebar;
