import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import Select from "../../partials/actions/Select";
import accountServices from "../../services/account.services";
import Datepicker from "../../partials/actions/Datepicker";
import DateSelect from "../../partials/actions/DateSelect";
import { useStore } from "../../state/DashboardContext";
import { useUserStore } from "../../state/UserContext";

const DashboardFilters = observer(
  ({ startDate, endDate, sliceBy, accounts }) => {
    const [availableAccounts, setAvailableAccounts] = useState([]);
    const store = useStore();
    const userStore = useUserStore();

    const setStartDate = (val) => {
      const date = moment(val);
      if (date.isValid() && date.isAfter("1970-01-01")) {
        store.setStartDate(val);
      }
    };

    const setEndDate = (val) => {
      const date = moment(val);
      if (date.isValid() && date.isAfter(store.startDate)) {
        store.setEndDate(val);
      }
    };

    const handleDateSliceChange = (val) => {
      store.setSliceBy(val);
    };

    const handleSelect = (account) => {
      if (account && account.id) {
        store.setAccountId(account.id);
      }
    };

    useEffect(() => {
      const fetchAccounts = async () => {
        const { data } = await accountServices.search({
          skip: 0,
          limit: 1000,
          sortProperty: "name",
        });
        setAvailableAccounts(data);
        const initAccount =
          data.find((a) => parseInt(a.id) === parseInt(store.accountId)) ||
          data[0];
        store.setAccountId(initAccount.id);
      };
      if (
        userStore.user &&
        userStore.hasPermission(["READ_ALL_ACCOUNT_DATA"])
      ) {
        fetchAccounts();
      }
    }, [userStore.user, userStore, store]);

    return (
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Right: Actions */}
        <div className="grid md:grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          {/* Datepicker built with react-dates */}
          {startDate && (
            <Datepicker
              value={store.startDate}
              onChange={(val) => {
                setStartDate(val);
              }}
            />
          )}
          {startDate && <div className="pt-3"> {"->"} </div>}
          {endDate && (
            <Datepicker
              value={store.endDate}
              onChange={(val) => {
                setEndDate(val);
              }}
            />
          )}
          {sliceBy && (
            <DateSelect
              onChange={handleDateSliceChange}
              selectedOption={store.sliceBy}
            />
          )}
          {accounts &&
            userStore.user &&
            userStore.hasPermission(["READ_ALL_ACCOUNT_DATA"]) && (
              <Select
                options={availableAccounts}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={handleSelect}
                value={availableAccounts.filter(
                  (a) => parseInt(a.id) === parseInt(store.accountId)
                )}
              />
            )}
        </div>
      </div>
    );
  }
);

export default DashboardFilters;
