import React, { useEffect } from "react";
import moment from "moment";
import { focusHandling } from "cruip-js-toolkit";
import { Observer } from "mobx-react-lite";
import PaginationClassic from "../PaginationClassic";
import exportServices from "../../services/export.services";
import { useStore } from "../../state/OrderItemsContext";
import { useToasts } from "react-toast-notifications";
import Table from "../Table";

function OrderItemsTable({ startDate, endDate, filters, account }) {
  const PAGE_SIZE = 20;
  const store = useStore();
  const { addToast } = useToasts();

  const handleExport = async () => {
    const { data } = await exportServices.create({
      accountId: account,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      exportType: filters.reportType,
      systemFilter: filters.system,
      locationFilter: filters.location,
    });
    if (data) {
      addToast(
        "Export is being created!  Please visit the exports section to download.",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    }
  };

  const handlePrevClick = () => {
    store.setSkip(store.skip - PAGE_SIZE);
    store.fetch();
  };

  const handleNextClick = () => {
    store.setSkip(store.skip + PAGE_SIZE);
    store.fetch();
  };

  const handleSortClick = (property) => {
    if (store.sortProperty === property) {
      store.setSortDirection(store.sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      store.setSortDirection("ASC");
    }
    store.setSortProperty(property);
    store.fetch();
  };

  const columns = [
    {
      title: "Order ID",
      render: (row) => {
        return row.order_id;
      },
    },
    {
      title: "Gross Revenue",
      render: (row) => {
        return row.gross_revenue;
      },
    },
    {
      title: "Discount",
      render: (row) => {
        return row.discount;
      },
    },
    {
      title: "NetRevenue",
      render: (row) => {
        return row.net_revenue;
      },
    },
    {
      title: "Qty",
      render: (row) => {
        return row.quantity;
      },
    },
    {
      title: "Order Date",
      selected: store.sortProperty === "created_at",
      sortDirection: store.sortDirection,
      render: (row) => {
        return row.created_at
          ? moment.utc(row.created_at).format("MM/DD/yyyy")
          : "-";
      },
      onClick: () => handleSortClick("created_at"),
    },
    {
      title: "Cancelled Date",
      selected: store.sortProperty === "cancelled_at",
      sortDirection: store.sortDirection,
      render: (row) => {
        return row.cancelled_at
          ? moment.utc(row.cancelled_at).format("MM/DD/yyyy")
          : "-";
      },
      onClick: () => handleSortClick("cancelled_at"),
    },
  ];

  useEffect(() => {
    store.setLimit(PAGE_SIZE);
    store.setSortProperty("created_at");
    store.setFilters({ ...filters, accountId: account, startDate, endDate });
    store.setSkip(0);
    focusHandling("outline");
    if (account && filters.reportType) store.fetch();
  }, [account, filters, startDate, endDate, store]);

  return (
    <Observer>
      {() => (
        <div>
          <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
            <header className="px-5 py-4 border-b border-gray-100 flex items-center relative">
              <h2 className="font-semibold text-gray-800">
                Orders Items{" "}
                <span className="text-gray-400 font-medium">{store.total}</span>
              </h2>{" "}
              <button
                type="button"
                className="absolute btn bg-green hover:bg-green-dark text-white ml-3"
                style={{ right: "1rem", top: ".5rem" }}
                onClick={() => handleExport()}
              >
                Download
              </button>
            </header>
            <div>
              {/* Table */}
              <Table columns={columns} data={store.items} />
            </div>
          </div>
          {/* Pagination */}
          <div className="mt-8">
            <PaginationClassic
              limit={store.total > PAGE_SIZE ? PAGE_SIZE : store.total}
              skip={store.skip}
              total={store.total}
              previousClick={handlePrevClick}
              nextClick={handleNextClick}
            />
          </div>
        </div>
      )}
    </Observer>
  );
}

export default OrderItemsTable;
