import React, { useCallback, useState } from "react";
import { Observer } from "mobx-react-lite";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import OrdersTable from "../partials/orders/OrdersTable";
import DashboardFilters from "../partials/dashboard/DashboardFilters";
import { useStore } from "../state/DashboardContext";
import OrderFilters from "../partials/OrderFilters";

function Orders() {
  // const [systems, setSystems] = useState([]);
  // const [locations, setLocations] = useState([]);
  // const [reportTypes, setReportTypes] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [filters, setFilters] = useState({
    reportType: "orders",
  });

  const store = useStore();

  const onChangeCallback = useCallback((f) => {
    setFilters(f);
  }, []);

  return (
    <Observer>
      {() => (
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                      Orders
                    </h1>
                  </div>
                </div>

                <DashboardFilters startDate endDate accounts />
                <OrderFilters onChange={onChangeCallback} type="orders" />

                {/* Table */}
                <OrdersTable
                  account={store.accountId}
                  startDate={store.startDate}
                  endDate={store.endDate}
                  filters={filters}
                />
              </div>
            </main>
          </div>
        </div>
      )}
    </Observer>
  );
}

export default Orders;
