import { api } from "../initializers/axios";

const create = async ({ accountId, fullyLoaded, paidMarketing, date }) => {
  const { data } = await api.post("/marketing-spends", {
    accountId,
    fullyLoaded: Number.parseFloat(fullyLoaded).toFixed(2),
    paidMarketing: Number.parseFloat(paidMarketing).toFixed(2),
    date,
  });
  return { data };
};

const remove = async (ids) => {
  const { data } = await api.post("/marketing-spends/delete", { ids });
  return { data };
};

const search = async (filters = {}, options = {}) => {
  const optionsStr = Object.keys(options).reduce((str, f) => {
    str = `${str}&${f}=${options[f]}`;
    return str;
  }, "?");

  const filterStr = Object.keys(filters).reduce((str, f) => {
    str = `${str}&${f}=${filters[f]}`;
    return str;
  }, "");
  const x = api.get(`/marketing-spends${optionsStr}${filterStr}`);
  const { data, meta } = await x;
  return { data, meta };
};

const update = async (id, props) => {
  const { data } = await api.put(`/marketing-spends/${id}`, props);
  return { data };
};

const methods = {
  create,
  remove,
  search,
  update,
};

export default methods;
