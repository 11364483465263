import React, { useEffect, useState } from "react";
import Select from "../partials/actions/Select";
import { getQueryParams } from "../utils/QueryParams";
import { Observer } from "mobx-react-lite";
import { useStore } from "../state/DashboardContext";
import orderServices from "../services/order.services";
import orderItemsServices from "../services/order-item.services";
import transactionServices from "../services/transaction.services";

function OrderFilters({ onChange, type = "orders" }) {
  const [filters, setFilters] = useState({});
  const [reportTypes, setReportTypes] = useState([]);
  const [systems, setSystems] = useState([]);
  const [locations, setLocations] = useState([]);
  const store = useStore();

  const handleSelect = (option, type) => {
    const newFilters = { ...filters, [type]: option.id };
    setFilters(newFilters);
    onChange(newFilters);
  };

  useEffect(() => {
    const { reportType, system, location } = getQueryParams();
    const newFilters = {
      reportType: reportType || reportTypes[0]?.id || "",
      system: system || "",
      location: location || "",
    };
    setFilters(newFilters);
    onChange(newFilters);
  }, [reportTypes, onChange]);

  useEffect(() => {
    const fetchConfig = async () => {
      const service =
        type === "orders"
          ? orderServices
          : type === "order_items"
          ? orderItemsServices
          : transactionServices;
      const { systems, locations, report_types } = await service.config(
        store.accountId
      );
      const availableSystems = [{ id: "", name: "All" }, ...systems];
      const availableLocations = [{ id: "", name: "All" }, ...locations];
      setSystems(availableSystems);
      setLocations(availableLocations);
      setReportTypes(report_types);
    };
    if (store.accountId) fetchConfig();
  }, [store.accountId, type]);

  return (
    <Observer>
      {() => (
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          <div className="grid md:grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {reportTypes.length > 0 && (
              <div>
                <label className="block text-sm font-medium text-gray-600">
                  Report Type
                </label>
                <Select
                  label="Report Type"
                  options={reportTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  onChange={(option) => handleSelect(option, "reportType")}
                  value={reportTypes.filter((a) => a.id === filters.reportType)}
                />
              </div>
            )}
            {systems.length > 0 && (
              <div>
                <label className="block text-sm font-medium text-gray-600">
                  System
                </label>
                <Select
                  options={systems}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  onChange={(option) => handleSelect(option, "system")}
                  value={systems.filter((a) => a.id === filters.system)}
                />
              </div>
            )}
            {locations.length > 0 && (
              <div>
                <label className="block text-sm font-medium text-gray-600">
                  Location
                </label>
                <Select
                  options={locations}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  onChange={(option) => handleSelect(option, "location")}
                  value={locations.filter((a) => a.id === filters.location)}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Observer>
  );
}

export default OrderFilters;
