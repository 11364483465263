import React from "react";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { useStore } from "../../state/UsersContext";
import { useUserStore } from "../../state/UserContext";

function UsersTableItem({ item, handleClick, isChecked, showCheckboxes }) {
  const store = useStore();
  const userStore = useUserStore();
  const { addToast } = useToasts();

  const updateUser = async (id, isAdmin) => {
    await store.update(id, { isAdmin });
    addToast("User Updated!", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        {showCheckboxes && (
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select</span>
              <input
                id={`check-account-${item.id}`}
                className="form-checkbox"
                type="checkbox"
                onChange={(e) => handleClick(e, item.id)}
                checked={isChecked}
              />
            </label>
          </div>
        )}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{item.email}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{item.account.name}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">
          {moment(item.createdAt).format("MM/DD/yyyy")}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          <div
            className="form-switch focus-within:shadow-outline"
            style={{ margin: "0 auto" }}
          >
            <input
              type="checkbox"
              id={`user-admin-${item.id}`}
              className="sr-only"
              checked={item.isAdmin}
              onChange={() => updateUser(item.id, !item.isAdmin)}
              disabled={
                !userStore.user || !userStore.hasPermission(["MANAGE_USERS"])
              }
            />
            <label className="bg-gray-400" htmlFor={`user-admin-${item.id}`}>
              <span className="bg-white shadow-sm" aria-hidden="true"></span>
              <span className="sr-only">Account Admin</span>
            </label>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default UsersTableItem;
