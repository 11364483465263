import React from "react";
import { createStore } from "./dashboardStore";
import { useLocalObservable } from "mobx-react-lite";

const Context = React.createContext(null);

export const DashboardProvider = ({ children }) => {
  const store = useLocalObservable(createStore);

  return <Context.Provider value={store}>{children}</Context.Provider>;
};

export const useStore = () => React.useContext(Context);
