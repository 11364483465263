import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import "./css/style.scss";

import { focusHandling } from "cruip-js-toolkit";
import "./charts/ChartjsConfig";

// Import pages
import CustomerAcquisitions from "./pages/CustomerAcquisitions";
import Forecasting from "./pages/Forecasting";
import LTV from "./pages/LTV";
import Retention from "./pages/Retention";
import Users from "./pages/Users";
import Accounts from "./pages/Accounts";
import Admins from "./pages/Admins";
import ContributionMargins from "./pages/ContributionMargins";
import Exports from "./pages/Exports";
import MarketingSpend from "./pages/MarketingSpend";
import Orders from "./pages/Orders";
import OrderItems from "./pages/OrderItems";
import Overview from "./pages/Overview";
import Settings from "./pages/Settings";
import Signin from "./pages/Signin";
import Transactions from "./pages/Transactions";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

import { AccountsProvider } from "./state/AccountsContext";
import { AdminsProvider } from "./state/AdminsContext";
import { DashboardProvider } from "./state/DashboardContext";
import { MSProvider } from "./state/MSContext";
import { OrdersProvider } from "./state/OrdersContext";
import { OrderItemsProvider } from "./state/OrderItemsContext";
import { TransactionsProvider } from "./state/TransactionsContext";

import { UsersProvider } from "./state/UsersContext";
import { CMProvider } from "./state/CMContext";
import { ExportsProvider } from "./state/ExportsContext";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <ToastProvider>
        <DashboardProvider>
          <Switch>
            <Route exact path="/overview">
              <Overview />
            </Route>
            <Route exact path="/acquisitions">
              <CustomerAcquisitions />
            </Route>
            <Route exact path="/retention">
              <Retention />
            </Route>
            <Route exact path="/ltv">
              <LTV />
            </Route>
            <Route exact path="/forecasting">
              <Forecasting />
            </Route>
            <Route exact path="/users">
              <UsersProvider>
                <Users />
              </UsersProvider>
            </Route>
            <Route exact path="/accounts">
              <AccountsProvider>
                <Accounts />
              </AccountsProvider>
            </Route>
            <Route exact path="/admins">
              <AdminsProvider>
                <Admins />
              </AdminsProvider>
            </Route>
            <Route exact path="/orders">
              <OrdersProvider>
                <Orders />
              </OrdersProvider>
            </Route>
            <Route exact path="/order-items">
              <OrderItemsProvider>
                <OrderItems />
              </OrderItemsProvider>
            </Route>
            <Route exact path="/transactions">
              <TransactionsProvider>
                <Transactions />
              </TransactionsProvider>
            </Route>
            <Route exact path="/marketing-spend">
              <MSProvider>
                <MarketingSpend />
              </MSProvider>
            </Route>
            <Route exact path="/contribution-margins">
              <CMProvider>
                <ContributionMargins />
              </CMProvider>
            </Route>
            <Route exact path="/settings">
              <CMProvider>
                <AccountsProvider>
                  <Settings />
                </AccountsProvider>
              </CMProvider>
            </Route>
            <Route exact path="/exports">
              <ExportsProvider>
                <Exports />
              </ExportsProvider>
            </Route>
            <Route exact path="/signin">
              <Signin />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route exact path="/reset-password">
              <ResetPassword />
            </Route>
            <Route path="*">
              <Redirect to="/overview" />
            </Route>
          </Switch>
        </DashboardProvider>
      </ToastProvider>
    </>
  );
}

export default App;
