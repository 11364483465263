import React from "react";
import { formatNumber } from "../../utils/Utils";

function LTVTableItem({ position, item, defaultValue }) {
  if (!defaultValue) defaultValue = 0;
  return (
    <tr className={position % 2 !== 0 ? "bg-gray-100" : ""}>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{item.cohort}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">{formatNumber(item.customers)}</div>
      </td>
      {Object.keys(item.values).map((k) => {
        return (
          <td
            key={k}
            className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
          >
            <div className="text-center">
              {item.values[k] ? formatNumber(item.values[k]) : defaultValue}
            </div>
          </td>
        );
      })}
    </tr>
  );
}

export default LTVTableItem;
