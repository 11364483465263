import { api } from "../initializers/axios";

const create = async ({ accountId, margin }) => {
  const { data } = await api.post("/contribution-margins", {
    accountId,
    margin: Number.parseFloat(margin).toFixed(2),
  });
  return { data };
};

const get = async (accountId) => {
  const { data } = await api.get(`/contribution-margins/${accountId}`);
  return { data };
};

const remove = async (ids) => {
  const { data } = await api.post("/contribution-margins/delete", { ids });
  return { data };
};

const search = async (filters = {}, options = {}) => {
  const optionsStr = Object.keys(options).reduce((str, f) => {
    str = `${str}&${f}=${options[f]}`;
    return str;
  }, "?");

  const filterStr = Object.keys(filters).reduce((str, f) => {
    str = `${str}&${f}=${filters[f]}`;
    return str;
  }, "");
  const x = api.get(`/contribution-margins${optionsStr}${filterStr}`);
  const { data, meta } = await x;
  return { data, meta };
};

const update = async (id, props) => {
  const { data } = await api.put(`/contribution-margins/${id}`, props);
  return { data };
};

const methods = {
  create,
  get,
  remove,
  search,
  update,
};

export default methods;
