import React from "react";
import Select from "react-select";

function MySelect(props) {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: "15rem",
      padding: "4px 8px",
      boxShadow: "0",
      borderColor: "inherit !important",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "rgb(103, 129, 55)" : "inherit",
      backgroundColor: state.isSelected
        ? "rgba(250, 250, 250, 1)"
        : "inherit !important",
      padding: 20,
    }),
  };
  return (
    <Select styles={customStyles} menuPortalTarget={document.body} {...props} />
  );
}

export default MySelect;
