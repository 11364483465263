import { api } from "../initializers/axios";

const create = async ({
  name,
  secret,
  username,
  amazonClientId,
  amazonClientSecret,
  amazonMarketplaceIds,
  amazonRefreshToken,
}) => {
  const { data } = await api.post("/accounts", {
    name,
    secret,
    username,
    amazonClientId,
    amazonClientSecret,
    amazonMarketplaceIds,
    amazonRefreshToken,
  });
  return { data };
};

const getById = async (id) => {
  const data = await api.get(`/accounts/${id}`);
  return data;
};

const remove = async (ids) => {
  const { data } = await api.post("/accounts/delete", { ids });
  return { data };
};

const search = async (filters = {}, options = {}) => {
  const optionsStr = Object.keys(options).reduce((str, f) => {
    str = `${str}&${f}=${options[f]}`;
    return str;
  }, "?");

  const filterStr = Object.keys(filters).reduce((str, f) => {
    str = `${str}&${f}=${filters[f]}`;
    return str;
  }, "");
  const x = api.get(`/accounts${optionsStr}${filterStr}`);
  const { data, meta } = await x;
  return { data, meta };
};

const sync = async (id, since = null, action = null) => {
  const { data } = await api.post(`/accounts/${id}/syncdata`, {
    since,
    action,
  });
  return { data };
};

const update = async (id, props) => {
  const { data } = await api.put(`/accounts/${id}`, props);
  return { data };
};

const methods = {
  create,
  getById,
  remove,
  search,
  sync,
  update,
};

export default methods;
