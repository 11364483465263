import React from "react";
import { createStore } from "./listStore";
import { useLocalObservable } from "mobx-react-lite";
import msServices from "../services/marketingspend.services";

const MSContext = React.createContext(null);

export const MSProvider = ({ children }) => {
  const store = useLocalObservable(createStore(msServices));

  return <MSContext.Provider value={store}>{children}</MSContext.Provider>;
};

export const useStore = () => React.useContext(MSContext);
