import React, { useEffect, useMemo, useState } from "react";
import { Observer } from "mobx-react-lite";
import { useToasts } from "react-toast-notifications";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardFilters from "../partials/dashboard/DashboardFilters";
import DeleteButton from "../partials/actions/DeleteButton";
import MSForm from "../partials/marketingspend/MSForm";
import MSTable from "../partials/marketingspend/MSTable";
import Modal from "../partials/Modal";
import PaginationClassic from "../partials/PaginationClassic";

import { useStore } from "../state/MSContext";
import { useStore as useDashStore } from "../state/DashboardContext";
import { useUserStore } from "../state/UserContext";

function MarketingSpend() {
  const PAGE_SIZE = 20;

  const [deleteError, setDeleteError] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const dashStore = useDashStore();
  const store = useStore();
  const userStore = useUserStore();
  const { addToast } = useToasts();

  const deleteMSs = async () => {
    const { error } = await store.remove();
    if (!error) {
      setDeleteModalOpen(false);
      setDeleteError("");
      // pop a toaster
      addToast("Marketing Spend(s) deleted!", {
        appearance: "success",
        autoDismiss: true,
      });
      fetchData();
    } else if (error) {
      setDeleteError(error);
    }
  };

  const handleMenuCallback = (action, payload) => {
    if (action === "edit") {
      setSelectedItem(payload);
      setModalOpen(true);
    }
  };

  const handleSelectedItems = (selectedItems) => {
    store.setSelectedItems(selectedItems);
  };

  const handlePrevClick = () => {
    store.setSkip(store.skip - PAGE_SIZE);
    fetchData();
  };

  const handleNextClick = () => {
    store.setSkip(store.skip + PAGE_SIZE);
    fetchData();
  };

  const fetchData = useMemo(
    () => async () => {
      if (userStore.user.accountId || dashStore.accountId) {
        store.setFilters({
          ...store.filters,
          accountId: userStore.user.accountId || dashStore.accountId,
        });
        await store.fetch();
      }
    },
    [store, userStore.user, dashStore.accountId]
  );

  useEffect(() => {
    store.setLimit(PAGE_SIZE);
    store.setSortProperty("date");
    store.setSortDirection("DESC");
    fetchData();
  }, [fetchData, store]);

  return (
    <Observer>
      {() => (
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {!userStore.user.accountId && <DashboardFilters accounts />}

                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                      Marketing Spend
                    </h1>
                  </div>

                  {/* Right: Actions */}
                  <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    {/* Delete button */}
                    {userStore.hasPermission(["MANAGE_MS"]) && (
                      <Modal
                        isOpen={deleteModalOpen}
                        buttonHandler={() => setDeleteModalOpen(true)}
                        cancelHandler={() => setDeleteModalOpen(false)}
                        button={
                          <DeleteButton selectedItems={store.selectedItems} />
                        }
                      >
                        <div className="flex-grow">
                          {/* Panel body */}
                          <div className="p-6 space-y-6">
                            {deleteError.length > 0 && (
                              <div className="mt-5">
                                <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                                  <span className="text-sm">{deleteError}</span>
                                </div>
                              </div>
                            )}
                            <section>
                              <h2 className="text-xl text-gray-800 font-bold mb-1">
                                Delete {store.selectedItems.length} Entries
                              </h2>
                              <div className="text-sm">
                                This can not be undone
                              </div>
                            </section>
                          </div>
                          {/* Panel footer */}
                          <footer>
                            <div className="flex flex-col px-6 py-5 border-t border-gray-200">
                              <div className="flex self-end">
                                <button
                                  type="button"
                                  className="btn border-gray-200 hover:border-gray-300 text-gray-600"
                                  onClick={() => setDeleteModalOpen(false)}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="btn bg-green hover:bg-green-dark text-white ml-3"
                                  onClick={deleteMSs}
                                >
                                  Yes, Delete
                                </button>
                              </div>
                            </div>
                          </footer>
                        </div>
                      </Modal>
                    )}
                    {userStore.hasPermission(["MANAGE_MS"]) && (
                      <Modal
                        isOpen={modalOpen}
                        buttonHandler={() => setModalOpen(true)}
                        cancelHandler={() => {
                          setModalOpen(false);
                        }}
                        button={
                          <button className="btn bg-green hover:bg-green-dark text-white">
                            <svg
                              className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                            </svg>
                            <span className="hidden xs:block ml-2">
                              Add Marketing Spend
                            </span>
                          </button>
                        }
                      >
                        <MSForm
                          item={selectedItem}
                          submitHandler={() => {
                            setModalOpen(false);
                            setSelectedItem({});
                            addToast("Marketing Spend(s) created!", {
                              appearance: "success",
                              autoDismiss: true,
                            });
                            fetchData();
                          }}
                          cancelHandler={() => {
                            setModalOpen(false);
                            fetchData();
                          }}
                        />
                      </Modal>
                    )}
                  </div>
                </div>

                {/* Table */}
                <MSTable
                  accountId={dashStore.accountId}
                  showCheckboxes={userStore.hasPermission(["MANAGE_MS"])}
                  selectedItems={store.selectedItems}
                  onSelectedChange={handleSelectedItems}
                  list={store.items}
                  menuCallback={handleMenuCallback}
                  fetchCallback={fetchData}
                />

                {/* Pagination */}
                <div className="mt-8">
                  <PaginationClassic
                    limit={store.total > PAGE_SIZE ? PAGE_SIZE : store.total}
                    skip={store.skip}
                    total={store.total}
                    previousClick={handlePrevClick}
                    nextClick={handleNextClick}
                  />
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </Observer>
  );
}

export default MarketingSpend;
